import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'

import { HTTP } from '@ionic-native/http/ngx'
import { Platform } from '@ionic/angular'
import { catchError, map } from 'rxjs/operators'
import { from, throwError } from 'rxjs'

import { WzoHttpUtil } from './wzoHttp.util'

@Injectable({
  providedIn: 'root'
})
export class WzoHttp {

  constructor(
    private nativeHttp: HTTP,
    private platform:   Platform,
    private util:       WzoHttpUtil,
    public http:        HttpClient
  ) { }


  get(url, options?) {

    url = this.util.url(url)
    options = this.util.options(options)
    
    if (this.platform.is('cordova')) {
      return from(this.nativeHttp.get(url, {}, {})).pipe(
        map(
          resp => {
            resp = JSON.parse(resp.data) 
            return resp
          }
        ),
        catchError(this.util.wrapError),
      )
    }

    return this.http.get(url, options).pipe(
      catchError(this.util.wrapError)
    )
  }

  post(url, data, options?) {

    url = this.util.url(url)
    options = this.util.options(options)
    
    if (this.platform.is('cordova')) {
      return from(this.nativeHttp.post(url, JSON.parse(data), {})).pipe(
        map(
          resp => {
            resp = JSON.parse(resp.data) 
            return resp
          }
        ),
        catchError(this.util.wrapError)
      )
    }

    return this.http.post(url, data, options).pipe(
      catchError(this.util.wrapError)
    )
  }

  put(url, data, options?) {

    url = this.util.url(url)
    options = this.util.options(options)

    return this.http.put(url, data, options).pipe(
      catchError(this.util.wrapError)
    )
  }

  delete(url, options?) {
    url = this.util.url(url)
    options = this.util.options(options)

    return this.http.delete(url, options).pipe(
      catchError(this.util.wrapError)
    )
  }

}

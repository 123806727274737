import { Injectable, NgZone } from '@angular/core'

import { GoogleAnalyticsIntegration } from './integrations/google-analytics.integration'

@Injectable()
export class WezeolyticsService {

  _counter = 0
  _sessionStartTime = Date.now()
  _window = (<any>window)

  constructor(
    private googleAnalytics: GoogleAnalyticsIntegration,
    private zone: NgZone
  ) {}

  event(category, action, label, route) {
    this.zone.runOutsideAngular( () => {
        let log = {
          category: category,
          action: action,
          label: label,
          route: route,
        }

        this.googleAnalytics.send(category, action, label, route)

        this._counter++

        if (localStorage.getItem('wzo_wezeolyticsVerbose')) {
          console.log(`${this._counter} WEZEOLYTICS: Log added: ${log.category} « ${log.action} « ${log.label}`)
        }
      }
    )
  }

  setDimension(name, value) {
    if (!this._window.ga) {
      console.error('Google Analytics is missing!')
      return
    }

    this.zone.runOutsideAngular(() => this._window.ga('set', name, value))
  }

}

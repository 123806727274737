import { Injectable } from '@angular/core'

import * as moment from 'moment'

@Injectable({
	providedIn: 'root'
}) 
export class CallHistoryUtil {
	
	constructor() {}
	
	mapCallHistory(calls) {
		
		let mappedCalls = []
		
		for (let call of calls) {
			let callGroup = this.getCallGroup(call)
			let targetGroup = mappedCalls.find(group => group && group.name == callGroup)
			
			if (typeof targetGroup == 'undefined') {
				mappedCalls.push({name: callGroup, calls: [call]})
				continue
			}
			
			targetGroup.calls.push(call)
		}
		
		return mappedCalls
	}
	
	getCallGroup(call) {
		let callGroup
		
		if (moment().isSame(call.calldate, 'day')) {
			callGroup = 'today'
		} else if (moment().subtract(1, 'days').isSame(call.calldate, 'day')) {
			callGroup = 'yesterday'
		} else {
			callGroup = 'older'
		}
		
		return callGroup
	}
	
}

import {
  Component,
  OnInit,
  OnDestroy
}                                   from '@angular/core'

import { PopoverController }        from '@ionic/angular'
import { Subscription }             from 'rxjs'

import { AuthService }              from '../../user/auth.service'
import { UserService, UserStatus }  from '../../user/user.service'
import { CldUserStatusPopover }     from '../user-status-popover/user-status-popover.component'
import { SipService }               from 'src/app/pluginz/wezeo/sip/sip.service'

@Component({
  selector: 'ct-user-status',
  templateUrl: './user-status.component.html',
  styleUrls: ['./user-status.component.scss'],
})
export class CldUserStatus implements OnInit, OnDestroy {

  userStatusMenu: CldUserStatusPopover
  userStatus
  userStatusEnum = UserStatus

  _getUserStatusSubscription$: Subscription

  constructor(
    public popoverController: PopoverController,

    private authService:      AuthService,
    private sipService:       SipService,
    private userService:      UserService,
  ) { }

  ngOnInit() {
    this._getUserStatusSubscription$ = this.userService.getUserStatus$().subscribe(
      status => {
        this.userStatus = status

        if (status == UserStatus.Offline) {
          this.sipService.disconnect()
        } else if (status == UserStatus.Online) {
          this.authService.sipAuth().subscribe()
        }
      }
    )
  }

  ngOnDestroy() {
    this._getUserStatusSubscription$.unsubscribe()
  }

  async togglePopover(event) {
    const popover = await this.popoverController.create({
      cssClass: 'ct-popover ct-popover--userStatus',
      component: CldUserStatusPopover,
      event: event,
      translucent: false
    })

    return await popover.present()
  }

}

import { Component, OnInit } from '@angular/core'
import { ModalController } from '@ionic/angular'
import { CallService } from '../../call/call.service'

@Component({
  selector: 'ct-call-incoming-modal',
  templateUrl: './call-incoming-modal.component.html',
  styleUrls: ['./call-incoming-modal.component.scss'],
})
export class CtCallIncomingModal implements OnInit {

  constructor(
    public modalController: ModalController,
    private callService:    CallService,
  ) { }

  ngOnInit() {}

  closeModal = () => {
    return this.modalController.dismiss()
  }
  
  rejectCall() {
    this.callService.rejectCall()
    this.closeModal()
  }
  
  answerCall() {
    this.callService.answerCall()
    this.closeModal()
  }

}

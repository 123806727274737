import { 
  Component, 
  OnInit, 
  ElementRef, 
  ChangeDetectionStrategy, 
  ChangeDetectorRef 
}                             from '@angular/core'

import { TranslateService }   from '@ngx-translate/core'

import { WzoErrorService }    from './wzoError.service'

@Component({
  selector: 'wzo-errors',
  templateUrl: './wzoError.component.html',
  styleUrls: ['./wzoError.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class WzoErrorComponent implements OnInit {

  messages = []
  state = ''

  constructor(
    public ref:               ElementRef,
	  public translate:         TranslateService,
    public wzoErrorService:   WzoErrorService,
    
    private changeDetector:   ChangeDetectorRef
	) {
    this.wzoErrorService.wzoMessageEvent$.subscribe(message => {
      this.add(message)
    })
    
    this.wzoErrorService.wzoCloseMessageEvent$.subscribe(message => {
      this.remove(message)
    })
  }

  ngOnInit() {}

  add = (message) => {
		this.state = 'showing'
    // this.wzoErrorService.emitErrorEvent(error)
		this.messages.push({
			text: message.text,
      type: message.type
		})
    this.changeDetector.detectChanges()
	}
  
  remove = (message) => {
    this.messages = this.messages.filter(m => m.text != message.text && m.type != message.type)
    this.changeDetector.detectChanges()
  }

	clear = (state = 'hidden') => {
		this.state = state

		if (state !== 'hiding') {
			this.messages = []
			return
		}

		window.setTimeout(() => {
			this.messages = []
			this.state = 'hidden'
		}, 200)
	}
}

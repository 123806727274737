import { Injectable }   from '@angular/core'

import { of }           from 'rxjs'

import { ENV }          from 'src/environments/environment'
import { WzoHttp }      from '../../wezeo/wzoHttp/wzoHttp.service'
import { WzoHttpAuth }  from '../../wezeo/wzoHttp/wzoHttpAuth.service'

@Injectable({
  providedIn: 'root'
})
export class AuthApi {
	
	constructor(
    private wzoHttp:      WzoHttp,
    private wzoHttpAuth:  WzoHttpAuth
  ) {}
	
	login(email, password) {
    if (ENV.mocks) {
      return this.wzoHttp.get('/assets/mocks/login.json')
    }
    
    return this.wzoHttp.post('users/token.json', JSON.stringify({email, password}))
	}
  
  logout() {
    if (ENV.mocks || 1) {
      return this.wzoHttp.get('/assets/mocks/logout.json')
    }
    
    return of({})
  }
  
  sipAuth() {
    if (ENV.mocks) {
      return of({})
    }
    
    return this.wzoHttpAuth.get('user-endpoints/webrtc/3.json')
  }
}

import { Injectable } from '@angular/core'

import { AlertController } from '@ionic/angular'
import { TranslateService } from '@ngx-translate/core'
import { forkJoin } from 'rxjs'

@Injectable({
  providedIn: 'root'
})
export class AlertUtil {

  constructor(
    public alertCtrl: AlertController,
    public translate: TranslateService
  ) { }

  async show(title, message, buttons, cssClasses = 'wezeo-alert') {

    const alert = await this.alertCtrl.create({
      header: title,
      // subHeader: '',
      message: message,
      buttons: buttons,
      cssClass: cssClasses
    });
    await alert.present();
  }

  success = (message) => {
    forkJoin(
      this.translate.get('Úspech'),
      this.translate.get(message || ''),
      this.translate.get('Zatvoriť')
    ).subscribe(translations => {
      this.show(translations[0], translations[1], [translations[2]])
    })
  }

  error = (message) => {

    console.error('WZO ERROR: alertUtil.error', message)

    forkJoin(
      this.translate.get('Nastala chyba'),
      this.translate.get(message || 'Neznáma chyba'),
      this.translate.get('Zatvoriť')
    ).subscribe(translations => {
      this.show(translations[0], translations[1], [translations[2]])
    })
  }

}

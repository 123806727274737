import { Injectable, Output, EventEmitter } from '@angular/core';

@Injectable({providedIn: 'root'})
export class WzoErrorService {

  @Output() wzoErrorEvent$ = new EventEmitter()
  @Output() wzoMessageEvent$ = new EventEmitter()
  @Output() wzoCloseMessageEvent$ = new EventEmitter()

  constructor() {}
  
  addMessage(message) {
    this.wzoMessageEvent$.emit(message)
  }
  
  closeMessage(message) {
    this.wzoCloseMessageEvent$.emit(message)
  }

  emitErrorEvent(error) {
    this.wzoErrorEvent$.emit(error)
  }
}

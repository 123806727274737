import { Pipe, PipeTransform } from '@angular/core'
/*
 * Prida pocitadlo na sekundy
 * result: 00:23...00:24...
 * Usage:
 *   value | playbackTime:default
 * Example:
 *   {{ 2 |  playbackTime}}
 */
@Pipe({
    name: 'playbackTime'
})
export class PlaybackTimePipe implements PipeTransform {

    transform(value, def: string): any {
        if (value === null || value < 0) {
            return def ? def : '-'
        }

        let pad = '00'
        value = parseInt(value, 10)
        let min = Math.floor(value / 60).toString()
        let sec = (value % 60).toString()

        let paddedMin =  pad.substring(0, pad.length - min.length) + min
        let paddedSec =  pad.substring(0, pad.length - sec.length) + sec
        return paddedMin + ':' + paddedSec
    }
}

import { Injectable } from '@angular/core'

import { of } from 'rxjs'

import { ENV } from 'src/environments/environment'
import { WzoHttp } from '../../wezeo/wzoHttp/wzoHttp.service'
import { WzoHttpAuth } from '../../wezeo/wzoHttp/wzoHttpAuth.service'

@Injectable({
  providedIn: 'root'
})
export class UserApi {

	constructor(
    private wzoHttp: WzoHttp,
    private wzoHttpAuth: WzoHttpAuth,
  ) {}

  callHistory() {
    if (ENV.mocks) {
      return this.wzoHttp.get('/assets/mocks/call-history.json')
    }

    return this.wzoHttpAuth.get('call-history.json')
  }

  agents() {
    if (ENV.mocks) {
      return of({})
    }

    return this.wzoHttpAuth.get('agents.json')
  }

  countries() {
    if (ENV.mocks) {
      return of({})
    }

    return this.wzoHttpAuth.get('countries.json')
  }

  agentsList() {
    if (ENV.mocks) {
      return of({})
    }

    return this.wzoHttpAuth.get('agents/agent-list.json')
  }

  transferListOfAgents() {
    if (ENV.mocks) {
      return of({})
    }

    return this.wzoHttpAuth.get('agents/transfer-list.json')
  }

  userContacts() {
    if (ENV.mocks) {
      // return this.wzoHttp.get('/assets/mocks/call-history.json')
      return of({})
    }

    return this.wzoHttpAuth.get('contacts.json')
  }

  reset(email) {
    if (ENV.mocks || 1) {
      return this.wzoHttp.get('/assets/mocks/reset.json')
    }

    return of({})
  }
}

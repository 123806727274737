import {
  Component,
  OnInit,
  Output,
  Input,
  OnDestroy
}                                           from '@angular/core'

import { ModalController }                  from '@ionic/angular'
import {
  interval,
  BehaviorSubject,
  timer,
  Subscription
 }                                          from 'rxjs'
import { takeWhile, finalize }              from 'rxjs/operators'
import { AsYouTypeFormatter }               from 'google-libphonenumber'

import { CallService, CallStatus }          from '../../call/call.service'
import { CallingService }                   from '../../call/calling.service'
import { UserService }                      from '../../user/user.service'
import { PhoneNumberUtilService }           from '../../call/phonenumber.util'
import { InternationalPrefixModal }         from '../international-prefix-modal/international-prefix-modal.component'

@Component({
  selector: 'ct-keypad-input',
  templateUrl: './ct-keypad-input.component.html',
  styleUrls: ['./ct-keypad-input.component.scss'],
})
export class CtKeypadInput implements OnInit, OnDestroy {

  _countryChangeAutodetected = false

  country
  countries = []
  keypadInput = ''
  dialedNumber = ''
  formatter
  phoneNumberPrefix

  _callNumberSubscription$: Subscription
  _getCountrySubscription$: Subscription
  _keypadDialSubscription$: Subscription

  @Input() isDialpad = false
  @Output() _finishFullDeleteEvent = new BehaviorSubject<boolean>(false)

  constructor(
    private callService:      CallService,
    private callingService:   CallingService,
    private modalController:  ModalController,
    private phoneUtil:        PhoneNumberUtilService,
    private userService:      UserService
  ) {}

  ngOnInit() {
    this._keypadDialSubscription$ = this.callService.keypadDial$().subscribe(event => {
      if (this.isDialpad && event.isDialpad) {
        this.dialedNumber = this.dialedNumber + event.value
        this.formatPhoneNumber()

        if (this.dialedNumber.length > 0) {
          this.callingService.setCallingEnabled(true)
        }
        return
      }

      this.keypadInput += event.value
      this.callService.setKeypadValue(this.keypadInput)
    })
    this._getCountrySubscription$ = this.callingService.getCountry$().subscribe(country => {
      if (!country) {
        return
      }

      this.country = country

      if (this.isDialpad) {
        this.formatNumberPrefix()
        this.formatPhoneNumber()
      }
    })
    this._callNumberSubscription$ = this.callingService.getCallNumber$().subscribe(number => {
      if (this.isDialpad) {
        this.keypadInput = number
        if (
          this.callService.getCallStatus$().getValue() != CallStatus.Idle &&
          number == ''
        ) {
          this.dialedNumber = ''
          this.keypadInput = `+${this.country.calling_code}`
        }
      }
    })
    this.userService.getCountries$().subscribe(countries => this.countries = countries)
  }

  ngOnDestroy() {
    this._callNumberSubscription$.unsubscribe()
    this._getCountrySubscription$.unsubscribe()
    this._keypadDialSubscription$.unsubscribe()
  }

  deleteNumber() {
    if (!this.isDialpad) {
      this.keypadInput = this.keypadInput.slice(0, -1)
      return
    }

    if (this.dialedNumber.length < 1) {
      // this.keypadInput = ''
      this.callingService.setCallNumber('')
      this.formatter.clear()
      this.callingService.setCallingEnabled(false)
      this.phoneNumberPrefix = false
      return
    }

    this.dialedNumber = this.dialedNumber.slice(0, -1)
    if (this.dialedNumber.length < 1) {
      this.callingService.setCallingEnabled(false)
    }

    this.formatPhoneNumber()
    return
  }

  diffDialedNumber(country) {
    if (
      this.dialedNumber.length &&
      (this.dialedNumber.length > `+${country.calling_code}`.length)
    ) {
      return this.dialedNumber.slice(-1)
    }

    return ''
  }

  formatPhoneNumber() {
    // this.keypadInput = ''
    this.callingService.setCallNumber('')
    this.formatter.clear()
    let number = this.phoneNumberPrefix ? `+${this.country.calling_code}${this.dialedNumber}` : this.dialedNumber
    let callNumber

    for (let digit of number) {
      callNumber = this.formatter.inputDigit(digit)
    }
    this.callingService.setCallNumber(callNumber)
    this._autodetectCountryChange()

    if (this.phoneUtil.isValidNumber(this.keypadInput, this.country.calling_code)) {
      // this.keypadInput = this.phoneUtil.format(this.keypadInput, this.country.calling_code)
      // this.callingService.setCallNumber(this.keypadInput)
      this.callingService.setCallNumber(this.phoneUtil.format(this.keypadInput, this.country.calling_code))
      return
    }
  }

  formatNumberPrefix() {
    this.formatter = new AsYouTypeFormatter(this.country.country_code)

    if (!this.phoneNumberPrefix) {
      this.dialedNumber = this._countryChangeAutodetected ? this.diffDialedNumber(this.country) : ''
    }
    this.phoneNumberPrefix = true

    if ((!this.dialedNumber.length && this.country.id) || this._countryChangeAutodetected) {
      this.keypadInput = `+${this.country.calling_code}`

      if (this._countryChangeAutodetected) {
        this.keypadInput += this.dialedNumber
      }

      for (let char of this.keypadInput) {
        this.formatter.inputDigit(char)
      }

      return
    }

    this._countryChangeAutodetected = false
  }

  async selectInternationalPrefix() {

    const modal = await this.modalController.create({
      animated: true,
      cssClass: 'ct-modal ct-modal--partial',
      component: InternationalPrefixModal,
      componentProps: { countries: this.countries }
    })

    return await modal.present()
  }

  _autodetectCountryChange() {
    let formatterCountryCode = this.formatter.currentMetadata_.values_[9]

    if (this.country.country_code != formatterCountryCode) {
      let formatterCountry = this.countries.find(
        country => country.country_code == formatterCountryCode
      )

      if (typeof formatterCountry != 'undefined') {
        this._countryChangeAutodetected = true
        this.callingService.changeCountry(formatterCountry)
      }
    }
  }

  startFullDeleteCounter() {
    interval(10).pipe(
      // interval starts with 1s delay, first value then is 0, then 1, etc.
      takeWhile(val => !this._finishFullDeleteEvent.getValue() && val < 100),
      finalize(() => {
        if (!this._finishFullDeleteEvent.getValue()) {
          if (this.isDialpad) {
            this.dialedNumber = ''
            // this.keypadInput = ''
            this.callingService.setCallNumber('')
            this.formatter.clear()
            this.callingService.setCallingEnabled(false)
            return
          }

          this.keypadInput = ''
        }
      })
    ).subscribe()
  }

  finishFullDeleteCounter() {
    this._finishFullDeleteEvent.next(true)

    // reset subject to false for subsequent use
    timer(100).pipe(
      finalize(() => {
        this._finishFullDeleteEvent.next(false)
      })
    ).subscribe()
  }
}

import { Component, OnInit, Input }   from '@angular/core'

import { ModalController }            from '@ionic/angular'
import { CallingService }             from '../../call/calling.service'

@Component({
  selector: 'app-international-prefix-modal',
  templateUrl: './international-prefix-modal.component.html',
  styleUrls: ['./international-prefix-modal.component.scss'],
})
export class InternationalPrefixModal implements OnInit {
  
  @Input() countries

  constructor(
    private modalController:  ModalController,
    private callingService:   CallingService,
  ) { }

  ngOnInit() {}

  closeModal = () => {
    this.modalController.dismiss()
  }

  selectCountry(country) {
    this.callingService.changeCountry(country)
    this.closeModal()
  }
  
  itemHeight = (item, index) =>  60
  
}

import { NgModule }           from '@angular/core'
import { CommonModule }       from '@angular/common'

import { IonicModule }        from '@ionic/angular'
import { TranslateModule }    from '@ngx-translate/core'

import { WzoErrorComponent }  from './wzoError.component'
import { CtFlashMessage }     from '../../cloudtalk/ui-components/ct-flash-message/ct-flash-message.component'

@NgModule({
  imports: [
    CommonModule,
    IonicModule,
    TranslateModule
  ],
  declarations: [
    WzoErrorComponent,
    CtFlashMessage,
  ],
	exports: [
    WzoErrorComponent,
    CtFlashMessage,
  ]
})
export class WzoErrorModule {}

import { Injectable }           from '@angular/core'

@Injectable({
  providedIn: 'root'
})
export class ClicksListenerService {

  constructor() {}

  start(wezeolyticsBehavior) {
    addEventListener(
      'mousedown',
      event => {
        let el = event.toElement

        while (el != document.getElementsByTagName('ion-app')[0]) {
          if (this.reduceDuplicity(el)) {
            return 0
          }

          el = el.parentElement
        }

        let path = `(${wezeolyticsBehavior.getElementPath(event.toElement)})`
        wezeolyticsBehavior.event('click', `${event.toElement.textContent} ${path}`, '')

      },
      false
    )
  }

  reduceDuplicity(el) {
    let elTagName = el.tagName

    if ( elTagName == 'ION-BUTTON' ) {
      return true
    }
    if ( elTagName == 'ION-BACK-BUTTON' ) {
      return true
    }
    if ( el.href != undefined ) {
      return true
    }
    if ( el.ngReflectRouterLink != undefined ) {
      return true
    }

    return false
  }

}

import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router }              from '@angular/router'

import { ModalController }     from '@ionic/angular'

import { CallService }         from '../../call/call.service'
import { UserService }         from '../../user/user.service'
import { CallPlaybackService } from '../../call-playback/call-playback.service';

@Component({
  selector: 'ct-call-detail-modal',
  templateUrl: './call-detail-modal.component.html',
  styleUrls: ['./call-detail-modal.component.scss'],
})
export class CtCallDetailModal implements OnInit, OnDestroy {
  
  agents
  callDetail
  contact

  callRecording
  isAudioLoaded = false
  isSeeking = false

  constructor(
    private callService:          CallService,
    private modalController:      ModalController,
    private router:               Router,
    private userService:          UserService,
    private callPlaybackService:  CallPlaybackService
  ) { }

  ngOnInit() {
    this.agents = this.userService.getRawAgents()
    this.contact = this.userService.userUtil.findContactByNumber(
      this.userService.getRawContacts(),
      this.callDetail.numbers.fullNumber
    )
    this.preparePlayback()
    console.log(this.callDetail)
  }
  
  async presentModal(data) {
    
    const modal = await this.modalController.create({
      cssClass: 'ct-modal',
      component: CtCallDetailModal,
      componentProps: { ...data }
    })
    
    return await modal.present()
  }

  ngOnDestroy() {
    if (this.callRecording) {
      this.callRecording.pause()
      this.callRecording.removeEventListener('loadedmetadata', () => {})
      this.callRecording.removeEventListener('timeupdate', () => {})
    }
  }
  
  agentEmailByName(agentName) {
    let agentMatch = this.agents.find(agent => agent.display_name.indexOf(agentName) != -1)
    
    return typeof agentMatch != 'undefined' ? agentMatch.email : ''
  }
  
  closeModal = () => {
    return this.modalController.dismiss()
  }
  
  call(callDetail) {
    this.closeModal().then(async () => {
      if (await this.callService.makeCall(callDetail.numbers.fullNumber)) {
        this.router.navigate(['/call/ongoing'])
      }
    })
  }
  
  callDuration(time) {
    if (time == 0) {
      return '-'
    }
    
    let h = Math.floor(time / 3600)
    let min = Math.floor(time / 60) 
    let seconds = time % 60
    
    let hours = h > 0 ? `${h}h` : ''
    
    return `${hours} ${min}m ${seconds}s`
  }

  preparePlayback() {
    if (this.callDetail && this.callDetail.callid) {
      this.callRecording = new Audio(this.callPlaybackService.getPlaybackUrl(this.callDetail.id))
      this.callRecording.addEventListener('loadedmetadata', () => {
        this.isAudioLoaded = true
      })
      this.callRecording.addEventListener('ended', () => {
        this.callRecording.pause()
      }, false)
      this.callRecording.addEventListener('timeupdate', () => {})
    } else {
      this.callRecording = null
    }
  }

  togglePlayPause() {
    if (!this.callRecording.paused) {
      this.callRecording.pause()
    } else {
      this.callRecording.play()
      // this.trackPlaying()
    }
  }

  startSeeking() {
    this.isSeeking = true
  }

  endSeeking() {
    this.isSeeking = false
  }

  jumpToTime(ev) {
    if (!this.isSeeking) {
      return
    }

    const time = (ev.detail.value * this.callRecording.duration) / 100
    this.callRecording.currentTime = time
    this.callRecording.play()
  }

  changePlaybackTime(time) {
    let newTime = this.callRecording.currentTime + time
    if (time < 0 && newTime < 0) {
      newTime = 0
    } else if (time > 0 && newTime > this.callRecording.duration) {
      newTime = this.callRecording.duration
      this.callRecording.pause()
    }

    this.callRecording.currentTime = newTime
    this.callRecording.play()
  }

}

import { Injectable, Output, EventEmitter } 						from '@angular/core'

import { BehaviorSubject, of } 				from 'rxjs'
import { map } 										from 'rxjs/operators'

import { CallingApi } 						from './calling.api'
import { PhoneNumberUtilService } from './phonenumber.util'

const MAX_RECENT_CALLERS = 3

@Injectable({
	providedIn: 'root'
})
export class CallingService {

	@Output() _dialEvent = new EventEmitter()
	@Output() _keypadDeleteEvent = new EventEmitter()

	_callingEnabled$ = new BehaviorSubject<boolean>(false)
	_callNumber$ = new BehaviorSubject<any>('')
	_callers$ = new BehaviorSubject<any>([])
	_caller$ = new BehaviorSubject<any>({
		name: '',
		number: ''
	})
	_country$ = new BehaviorSubject<any>({
		calling_code: 421,
		country_code: 'SK',
		id: 201
	})
	_recentCallers$ = new BehaviorSubject<Array<any>>([])

	constructor(
		private callingApi: CallingApi,
    public phoneUtil:   PhoneNumberUtilService,
	) {
		// this._caller$.next(this.MOCK_getCaller())
	}

	callers() {

		return this.callingApi.callers().pipe(
			map(
				resp => {
          if (!resp['success']) {
            return []
          }

					let storedRecentCallers = window.localStorage.getItem('ct_recentCallers')

					if (storedRecentCallers) {
						let recents = storedRecentCallers.split(',')
						let recentCallers = resp['data'].filter(caller => recents.indexOf(`${caller.id}`) != -1)
						this._recentCallers$.next(recentCallers.reverse())
					}

					this._callers$.next(resp['data'])
          return resp['data']
        }
			)
		)
	}

	getCallers$() {
		return this._callers$
	}

	getRecentCallers$() {
		return this._recentCallers$
	}

	getCaller$() {
		return this._caller$
	}

	getCountry$() {
		return this._country$
	}

	getCallingEnabled$() {
		return this._callingEnabled$
	}

	setCallingEnabled(value) {
		this._callingEnabled$.next(value)
	}

	getCallNumber$() {
		return this._callNumber$
	}

	setCallNumber(number) {
		this._callNumber$.next(number)
	}

	getDialEvent$() {
		return this._dialEvent
	}

	getDeleteEvent$() {
		return this._keypadDeleteEvent
	}

	changeCaller(caller) {

		caller._number = this.callerNumber(caller)
		caller._region = this.callerRegion(caller)

		let currentCaller = this.getCaller$().getValue()
		if (currentCaller && currentCaller.id == caller.id || !currentCaller.id) {
			this._caller$.next(caller)
			return of({})
		}

		return this.callingApi.changeCaller(caller.id).pipe(map(
			resp => {
				if (!resp['success']) {
					return []
				}

				this._saveCallerToRecents(caller)
				this._caller$.next(caller)
				return resp['data']
			}
		))
	}

	_saveCallerToRecents(caller) {
		let recents = this._recentCallers$.getValue()

		let callerIndex = recents.findIndex(recent => recent.id == caller.id)
		if (callerIndex != -1) {
			recents.splice(callerIndex, 1)
		} else if (recents.length == MAX_RECENT_CALLERS) {
			recents.pop()
		}

		recents.unshift(caller)
		this._recentCallers$.next(recents)
		let recentCallersIds = recents.map(recentCaller => recentCaller.id)
		window.localStorage.setItem('ct_recentCallers', recentCallersIds.join(','))
	}

	changeCountry(value) {
		this._country$.next(value)
	}

	dial(value) {
		this._dialEvent.emit(value)
	}

	keypadDelete() {
		this._keypadDeleteEvent.emit()
	}

	callerNumber(caller) {
    return this.phoneUtil.format(
      '+' + caller.country_code + caller.public_caller_id,
      caller.country_code
    )
  }

  callerRegion(caller) {
    return this.phoneUtil.regionCodeFromNumber('+' + caller.country_code + caller.public_caller_id)
  }

	clearCache() {
		this._callers$.next([])
		this._caller$.next({
			name: '',
			number: ''
		})
		this._country$.next({
			calling_code: 421,
			country_code: 'SK',
			id: 201
		})
	}

  MOCK_getCaller() {
    return {
			name: 'Wonka Candy Company',
			number: ''
		}
  }

}

import {
  Component,
  OnDestroy,
  OnInit,
  // NgZone,
}                                   from '@angular/core'
// import { Router }                   from '@angular/router'

import { Platform }                 from '@ionic/angular'
import { BackgroundMode }           from '@ionic-native/background-mode/ngx'
import {
  // Push,
  PushObject,
  // PushOptions
}                                   from '@ionic-native/push/ngx'
import { SplashScreen }             from '@ionic-native/splash-screen/ngx'
import { StatusBar }                from '@ionic-native/status-bar/ngx'
import { ScreenOrientation }        from '@ionic-native/screen-orientation/ngx'

import * as SIP                     from 'sip.js'
import { TranslateService }         from '@ngx-translate/core'

import { AuthService }              from './pluginz/cloudtalk/user/auth.service'
import { CallService, CallStatus }  from './pluginz/cloudtalk/call/call.service'
import { PermissionsService }       from './pluginz/cloudtalk/permissions/permissions.service'
import { WzoNotifications }         from './pluginz/wezeo/notifications/notifications.service'
import { WzoErrorService }          from './pluginz/wezeo/wzoError/wzoError.service'


declare const cordova
declare const VoIPPushNotification

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html'
})
export class AppComponent implements OnInit, OnDestroy {

  _backBtnSubscription
  _notificationsSubscription
  _tokenRefreshSubscription
  _pushkitToken
  _pushObject: PushObject

  constructor(
    private backgroundMode:     BackgroundMode,
    private platform:           Platform,
    private screenOrientation:  ScreenOrientation,
    private splashScreen:       SplashScreen,
    private statusBar:          StatusBar,

    private translate:          TranslateService,

    private authService:        AuthService,
    private callService:        CallService,
    private permissionsService: PermissionsService,
    // private push:               Push,
    private notifications:      WzoNotifications,
    private errorService:       WzoErrorService,
    // private zone:               NgZone,
  ) {
    this.initializeApp()
  }

  ngOnInit() {}

  ngOnDestroy() {
    this.backgroundMode.disable()

    if (this._backBtnSubscription) {
      this._backBtnSubscription.unsubscribe()
    }
  }

  initializeApp() {
    this.platform.ready().then(() => {
      this.statusBar.styleDefault()
      this.splashScreen.hide()

      this.translate.setDefaultLang('en')
      this.translate.use('en')

      this._onLogin()
      this._onLogout()

      if (!this.platform.is('cordova')) {
        return
      }

      this.screenOrientation.lock(this.screenOrientation.ORIENTATIONS.PORTRAIT)

      if (this.platform.is('ios')) {
        cordova.plugins.iosrtc.registerGlobals()
        this.overrideSIPWebRTC()
      }

      this.getPermissions()
      this.initBackgroundMode()
    })
    this.platform.pause.subscribe(() => {
      console.log('GONE TO BACKGROUND')
      if (this.callService.getCallStatus$().getValue() == CallStatus.Ringing) {
        this.callService._stopVibrationAndRinging()
      }
    })
    this.platform.resume.subscribe(() => {
      console.log('BROUGHT TO FOREGROUND')
      if (this.callService.getCallStatus$().getValue() == CallStatus.Ringing) {
        this.callService.setCanVibrateAndRing(true)
        this.callService.vibrateAndRing()
      }
    })
  }

  async getPermissions() {
    const permissionsEnabled = await this.permissionsService.getPermissions()
    if (!permissionsEnabled && this.platform.is('android')) {
      this.errorService.addMessage({
        text: 'Permissions not granted',
        type: 'warning'
      })
      // this.showPermissionsErrorModal()
    }
  }

  initBackgroundMode() {
    this.backgroundMode.setDefaults({ silent: true })
    this.backgroundMode.enable()
    this.backgroundMode.on('activate').subscribe(() => {
      this.backgroundMode.disableWebViewOptimizations()
    })
  }

  initPushkit() {
    const push = VoIPPushNotification.init()

    push.on('registration', (data) => {
      // data.deviceToken
      console.log('Pushkit registration', data.deviceToken)
      this._pushkitToken = data.deviceToken
      this.notifications.backendApi.registerDevice(data.deviceToken, 'ios-pushkit').subscribe()
    })

    push.on('notification', (data) => {
      console.log('pushkit notification', data)
      // localStorage.setItem('lastNotificationData', JSON.stringify(data))
      // this.callkitService.receiveCall(data.message || 'samo')
      this.authService.reregisterSip$().subscribe()
    })

    push.on('error', (e) => {
      alert(e)
    })
  }

  // initNotifications() {
  //
  //   this._pushObject = this.push.init(ENV.pushOptions)
  //
  //   this._pushObject.on('registration').subscribe(registration => {
  //     console.log(registration, JSON.stringify(registration))
  //     this._pushkitToken = registration.registrationId
  //     this.notifications.backendApi.registerDevice(registration.registrationId).subscribe()
  //   })
  //
  //   this._pushObject.on('notification').subscribe(notification => {
  //     console.log(notification)
  //     this._onNotification(notification)
  //   })
  //
  //   this._pushObject.on('error').subscribe(error => {
  //     console.log(error)
  //   })
  // }

  _onLogin() {
    this.authService.login$().subscribe(() => {
      if (!this._notificationsSubscription) {
        this._notificationsSubscription = this.notifications.register$(this._onNotification).subscribe()
        // this.notifications.handleTokenRefresh$().subscribe()
      }

      if (this.platform.is('cordova')) {
        // this.initNotifications()

        if (this.platform.is('ios')) {
          this.initPushkit()
        }
      }
    })
  }

  _onLogout() {
    this.authService.logout$().subscribe(() => {
      this.notifications.unregister()
      // TODO: unregister from pushkit notifications if possible, at least
      // if (this._pushkitToken) {
      //   this.notifications.backendApi.unregisterDevice(this._pushkitToken).subscribe()
      // }

      if (this._notificationsSubscription) {
        this._notificationsSubscription.unsubscribe()
        this._notificationsSubscription = null
      }
    })
  }

  overrideSIPWebRTC() {
    SIP.WebRTC.isSupported = function isSupported() { return true }
    SIP.WebRTC.MediaStream = cordova.plugins.iosrtc.MediaStream
    SIP.WebRTC.getUserMedia = cordova.plugins.iosrtc.getUserMedia
    SIP.WebRTC.RTCPeerConnection = cordova.plugins.iosrtc.RTCPeerConnection
    SIP.WebRTC.RTCSessionDescription = cordova.plugins.iosrtc.RTCSessionDescription
    SIP.WebRTC.RTCIceCandidate = cordova.plugins.iosrtc.RTCIceCandidate
    SIP.WebRTC.MediaStreamTrack = cordova.plugins.iosrtc.MediaStreamTrack
  }

  _onNotification = (notification) => {

    // console.log('notification', notification)
    // localStorage.setItem('onNotification', (new Date()).toISOString())

    if (this.platform.is('android')) {
      this.authService.reregisterSip$().subscribe(() => {
        setTimeout(() => {
          this.backgroundMode.unlock()
          // this.router.navigate(['call/ongoing'])
        }, 1000)
      })
    }

    // if (this.platform.is('ios')) {
    //   this.router.navigate(['call/ongoing'])
    // }

    if (!notification) {
      return
    }

    console.log('FCM notification handle:', notification)
    // localStorage.setItem(`${(new Date()).getTime()}`, notification.my_custom_key)

    // user tapped on notification
    // or it came while app is open and all notification data are available (including title)
    // if (notification.tap) {
    //   this.callService.setCanVibrateAndRing(true)
    //   this.callService.vibrateAndRing()
    //   return
    // }
    //
    // if (notification.title) {
    //   this.callService.setCanVibrateAndRing(true)
    //   return
    // }

    // data-message
    // this.zone.run(() => {
    //   this.authService.reregisterSip$().subscribe(() => {
    //
    //     if (this.platform.is('android')) {
    //       setTimeout(() => {
    //         this.backgroundMode.unlock()
    //       }, 1000)
    //     }
    //   })
    // })
  }
}

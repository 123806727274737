import { Component, OnInit, Input, ChangeDetectionStrategy } from '@angular/core'

import { TranslateService } from '@ngx-translate/core'

import { WzoErrorService } from 'src/app/pluginz/wezeo/wzoError/wzoError.service'

@Component({
  selector: 'ct-flash-message',
  templateUrl: './ct-flash-message.component.html',
  styleUrls: ['./ct-flash-message.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CtFlashMessage implements OnInit {
  
  @Input() message

  constructor(
    public translate: TranslateService,
    private wzoErrorService: WzoErrorService,
  ) { }

  ngOnInit() {}

  close() {
    this.wzoErrorService.closeMessage(this.message)
  }
  
}

import { NgModule }                 from '@angular/core'

import { PhoneNumberFormatterPipe } from './phone-number-formatter.pipe'
import { RegionFromNumberPipe }     from './region-from-number.pipe'

@NgModule({
  declarations: [
    PhoneNumberFormatterPipe,
    RegionFromNumberPipe
  ],
  exports: [
    PhoneNumberFormatterPipe,
    RegionFromNumberPipe
  ],
  providers: [
    PhoneNumberFormatterPipe,
    RegionFromNumberPipe
  ]
})
export class PhoneNumberFormatterModule {
  
}

import {
  Injectable, NgZone
}               from '@angular/core'

import { of, from }   from 'rxjs'
import { ENV }  from '../../../../../environments/environment'

@Injectable({
  providedIn: 'root'
})
export class WebNotifications {
  window = (<any>window)

  constructor(
    private zone: NgZone,
  ) {}

  register$(callback) {
    // prevent Firebase from triggering Angular change detection by calling ngZone runOutsideAngular
    return from(this.zone.runOutsideAngular(() => {
      this._firebase_initOnce()

      const messaging = this.window.firebase.messaging()
      this.DEV_debugMessagingOutput(messaging)

      return messaging.requestPermission()
        .then(() => messaging.getToken())
        .then(async token => {
          await callback(token)
          return token
        })
    }))
  }

  _firebase_initOnce() {
    if (!this.window.firebase.apps.length) {
      this.window.firebase.initializeApp(ENV.firebaseConfig)
    }
  }

  DEV_debugMessagingOutput(messaging) {
    messaging.onMessage(payload => console.log(payload))
  }

  onNotification$() {
    return of(null)
  }

  handleTokenRefresh$() {
    return of(null)
  }

  subscribeToTopic(topic) {
    console.warn(`subscribeToTopic (${topic}) not implemented in web api!`)
    return Promise.resolve()
  }

  unsubscribeFromTopic(topic) {
    console.warn(`unsubscribeFromTopic (${topic}) not implemented in web api!`)
    return Promise.resolve()
  }

  unregister() {
    console.warn('unregister does nothing in web api!')
    return Promise.resolve()
  }

  clearAllNotifications() {
    console.warn('clearAllNotifications does nothing in web api!')
    return Promise.resolve()
  }

}

import { Injectable, NgZone}        from '@angular/core'
import { Router }                   from '@angular/router'

import { RoutingListener }          from './features/routing.listener'
import { ApplicationStateService }  from './features/application-state.service'
import { ClicksListenerService }    from './features/clicks.listener'
import { WezeolyticsService }       from './wezeolytics.service'

@Injectable({
  providedIn: 'root'
})
export class WezeolyticsBehavior {

  constructor(
    private applicationState:   ApplicationStateService,
    private clickListener:      ClicksListenerService,
    private router:             Router,
    private routerService:      RoutingListener,
    private wezeolyticsService: WezeolyticsService,
    private zone:               NgZone,
  ) {
    this.zone.runOutsideAngular(() => {
      this.applicationState.start(this)
      this.clickListener.start(this)
      this.routerService.start(this)
    })
  }

  event(category, action, route) {
    this.routerService.logsInRoute++
    let formatedRoute = this._formatRoute(this.router.url)
    this.wezeolyticsService.event(category, action, formatedRoute, route)
  }

  getElementPath(el) {
    let path = [el.tagName]
    while (el != document.getElementsByTagName('ion-app')[0]) {
      el = el.parentElement
      path.push(el.tagName)
    }
    path.reverse()
    return (path.join('>'))
  }

  setGlobalDimensions(user) {
    this.wezeolyticsService.setDimension('dimension2', user.id)
  }

  _formatRoute(route) {
    let routeSplit = route.split('/')
    route = '/'

    for (let split of routeSplit) {
      route += (isNaN(Number(split))) ? split + '/' : 'ID/'
    }

    return route
  }
}

import { Injectable }                 from '@angular/core'

import { BehaviorSubject }            from 'rxjs'
import { fromEvent }                  from 'rxjs'
import { Network }                    from '@ionic-native/network/ngx'
import { ToastController, Platform }  from '@ionic/angular'
import { TranslateService }           from '@ngx-translate/core'

@Injectable({
  providedIn: 'root'
})
export class WzoOfflineService {

  _online$ = new BehaviorSubject<any>(true)

  constructor(
    private network:          Network,
    private platfrom:         Platform,
  ) {

    this.platfrom.ready().then(() => {
      let onlineStatus

      if (this.platfrom.is('cordova')) {
        onlineStatus = this.network.type !== 'none'
        this.nativeApi()
      } else {
        onlineStatus = navigator.onLine
        // this.webApi()
      }

      this._online$.next(onlineStatus)
    })
  }

  nativeApi() {
    this.network.onDisconnect().subscribe(() => this._online$.next(false))
    this.network.onConnect().subscribe(() => this._online$.next(true))
  }

  // webApi() {
    // tieto eventy nefunguju
    // fromEvent(window, 'offline').subscribe(() => this._online$.next(false))
    // fromEvent(window, 'online').subscribe(() => this._online$.next(true))
  // }

  getOnlineStatus$() {
    return this._online$
  }

  isOnline() {
    return this._online$.getValue()
  }
}

import { Directive, ElementRef, HostListener } from '@angular/core'

import { WezeolyticsBehavior }  from '../../wezeolytics-behavior'

@Directive({
  selector: 'ion-slides'
})
export class IonSlidesDirective {

  constructor(
    private el: ElementRef,
    private wezeolyticsBehavior: WezeolyticsBehavior
  ) {}

  @HostListener('ionSlideTap') ionSlideTapHandler() {
    this.wezeolyticsBehavior.event(
      'slides tap',
      this.wezeolyticsBehavior.getElementPath(this.el.nativeElement),
      ''
    )
  }

  @HostListener('ionSlideReachStart') ionSlideReachEndHandler() {
    this.wezeolyticsBehavior.event(
      'slides end',
      this.wezeolyticsBehavior.getElementPath(this.el.nativeElement),
      ''
    )
  }

  @HostListener('ionSlideReachStart') ionSlideReachStartHandler() {
    this.wezeolyticsBehavior.event(
      'slides start',
      this.wezeolyticsBehavior.getElementPath(this.el.nativeElement),
      ''
    )
  }

}

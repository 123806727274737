import { Pipe, PipeTransform }  from '@angular/core'

import { PhoneNumberUtil }      from 'google-libphonenumber'

/**
 * Vrati region z tel. cisla
 *
 * number: cislo v medzinar. formate
 * regionFromNumber: napr. SK pre +421900111222
 */
@Pipe({name: 'regionFromNumber'})
export class RegionFromNumberPipe implements PipeTransform {
  
  // constructor(
  //   private phoneUtil: PhoneNumberUtil
  // ) {}

    /**
     * Vrati region z tel. cisla
     *
     * @param number
     * @returns {string}
     */
    transform(number: string): string {
      let phoneUtil = PhoneNumberUtil.getInstance()  
      
        try {
          let phoneNumber = phoneUtil.parse(number.toString(), 'ZZ')
          return phoneUtil.getRegionCodeForNumber(phoneNumber)
        } catch (e) {
          return ''
        }
    }
}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { RouterlinkDirective } from './features/directives/routerlink.directive';
import { HrefDirective } from './features/directives/href.directive'
import { IonMenuDirective } from './features/directives/ionmenu-handler.directive';
import { IonButtonDirective } from './features/directives/ionbutton-handler.directive';
import { IonSlidesDirective } from './features/directives/ionslides-handler.directive'
import { IonbackbuttonDirective } from './features/directives/ionbackbutton-handler.directive'

@NgModule({
  declarations: [
    RouterlinkDirective,
    HrefDirective,
    IonButtonDirective,
    IonMenuDirective,
    IonSlidesDirective,
    IonbackbuttonDirective,
  ],
  imports: [
    CommonModule,
  ],
  exports: [
    RouterlinkDirective,
    IonButtonDirective,
    IonMenuDirective,
    HrefDirective,
    IonSlidesDirective,
    IonbackbuttonDirective,
  ]
})
export class AnalyticsModule {}

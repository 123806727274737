import { NgModule }                   from '@angular/core'
import { CommonModule }               from '@angular/common'
import { FormsModule }                from '@angular/forms'
import { RouterModule }               from '@angular/router'

import { IonicModule }                from '@ionic/angular'

import { WzoErrorModule }             from '../../wezeo/wzoError/wzoError.module'
import { WzoErrorComponent }          from '../../wezeo/wzoError/wzoError.component'
import { CtCaller }                   from '../ui-components/ct-caller/ct-caller.component'
import { CtCallerItem }               from '../ui-components/ct-caller-item/ct-caller-item.component'
import { CtCallAction }               from '../ui-components/call-action/call-action.component'
import { CtCallDetail }               from '../ui-components/call-detail/call-detail.component'
import { CtCallDetailModal }          from '../ui-components/call-detail-modal/call-detail-modal.component'
import { CldCallingNumberModal }      from '../ui-components/calling-number-modal/calling-number-modal.component'
import { CtCallTransferModal }        from '../ui-components/call-transfer-modal/call-transfer-modal.component'
import { CtCallIncomingModal }        from '../ui-components/call-incoming-modal/call-incoming-modal.component'
import { CtCallNote }                 from '../ui-components/call-note-modal/call-note-modal.component'
import { CtCallTags }                 from '../ui-components/call-tags-modal/call-tags-modal.component'
import { CtKeypad }                   from '../ui-components/ct-keypad/ct-keypad.component'
import { CtKeypadModal }              from '../ui-components/ct-keypad-modal/ct-keypad-modal.component'
import { CtKeypadInput }              from '../ui-components/ct-keypad-input/ct-keypad-input.component'
import { CtFlagCallPrefix }           from '../ui-components/ct-flag-call-prefix/ct-flag-call-prefix.component'
import { CldUserStatus }              from '../ui-components/user-status/user-status.component'
import { CldUserStatusPopover }       from '../ui-components/user-status-popover/user-status-popover.component'
import { PhoneNumberFormatterModule } from '../call/phone-number-formatter/phone-number-formatter.module'
import { InternationalPrefixModal }   from '../ui-components/international-prefix-modal/international-prefix-modal.component'
import { PlaybackTimePipe }           from '../call-playback/playback-time.pipe'
import { Settings }                   from '../ui-components/settings-modal/settings-modal.component'
import { SettingsInput }              from '../ui-components/settings-input-modal/settings-input-modal.component'
import { SettingsOptions }            from '../ui-components/settings-options-modal/settings-options-modal.component'
import { AnalyticsModule }            from './../../wezeo/wezeolytics/analytics.module'

@NgModule({
  declarations: [
    CtCaller,
    CtCallerItem,
    CtCallAction,
    CtCallDetail,
    CtCallDetailModal,
    CldCallingNumberModal,
    InternationalPrefixModal,
    CtCallTransferModal,
    CtCallIncomingModal,
    CtFlagCallPrefix,
    CldUserStatus,
    CldUserStatusPopover,
    PlaybackTimePipe,
    CtCallNote,
    CtCallTags,
    CtKeypad,
    CtKeypadModal,
    CtKeypadInput,
    Settings,
    SettingsInput,
    SettingsOptions
  ],
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    RouterModule,
    WzoErrorModule,
    PhoneNumberFormatterModule,
    AnalyticsModule
  ],
  exports: [
    WzoErrorComponent,
    CtCaller,
    CtCallerItem,
    CtCallAction,
    CtCallDetail,
    CtCallDetailModal,
    CldCallingNumberModal,
    CtKeypad,
    CtKeypadModal,
    CtKeypadInput,
    CtFlagCallPrefix,
    InternationalPrefixModal,
    CtCallTransferModal,
    CldUserStatus,
    CldUserStatusPopover,
    PhoneNumberFormatterModule,
    AnalyticsModule,
  ]
})
export class CloudtalkSharedModule {}

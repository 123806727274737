import { Injectable } from '@angular/core'
import { Platform } from '@ionic/angular'

declare const cordova
declare const navigator

@Injectable({
  providedIn: 'root'
})
export class PermissionsService {
  
  constructor(
    private platform: Platform
  ) {}

  async getPermissions() {
    
    if (this.platform.is('cordova')) {
      if (this.platform.is('ios')) {
        return await this.getiOSPermissions()
      } else if (this.platform.is('android')) {
        return await this.getAndroidPermissions()
      }
    }
    
    return Promise.resolve(true)
  }

  getiOSPermissions() {
    return new Promise((resolve) => {
      navigator.microphone((enabled: boolean) => {
        resolve(enabled)
      })
    })
  }

  getAndroidPermissions() {
    return new Promise((resolve) => {
      const cordovaPermissions = cordova.plugins.permissions

      const permissions = [
        cordovaPermissions.MODIFY_AUDIO_SETTINGS,
        cordovaPermissions.CALL_PHONE,
        cordovaPermissions.RECORD_AUDIO,
        cordovaPermissions.WAKE_LOCK
      ]

      cordovaPermissions.requestPermissions(
        permissions,
        (status) => {
          if (status.hasPermission) {
            resolve(true)
          } else {
            resolve(false)
          }
        }
      )
    })
  }
  
}

import { Component, OnInit }  from '@angular/core'

import { ModalController }    from '@ionic/angular'

import { CallService }        from '../../call/call.service'

@Component({
  selector: 'ct-keypad-modal',
  templateUrl: './ct-keypad-modal.component.html',
  styleUrls: ['./ct-keypad-modal.component.scss'],
})
export class CtKeypadModal implements OnInit {

  constructor(
    private callService:      CallService,
    private modalController:  ModalController
  ) { }

  ngOnInit() {}

  sendValueAndClose() {
    let value = this.callService.getKeypadValue()
    if (!value) {
      this.closeModal()
      return
    }

    this.callService.sendKeypress(value)
    this.callService.setKeypadValue('')
    this.closeModal()
  }

  closeModal = () => {
    return this.modalController.dismiss()
  }

}

import {
	Injectable,
	Output,
	EventEmitter
} 																	from '@angular/core'
import { Router } 									from '@angular/router'

import { BackgroundMode } 					from '@ionic-native/background-mode/ngx'
import { NativeAudio } 							from '@ionic-native/native-audio/ngx'
// import { NativeRingtones } 					from '@ionic-native/native-ringtones/ngx'
import { Vibration } 								from '@ionic-native/vibration/ngx'

import * as SIP 										from 'sip.js'
import {
	Platform,
	ModalController }									from '@ionic/angular'
import { BehaviorSubject, timer } 	from 'rxjs'
import { takeUntil } 								from 'rxjs/operators'

import { CallUtil } 								from './call.util'
import { CallingService } 					from './calling.service'
import { PhoneNumberUtilService } 	from './phonenumber.util'
import { UserService, UserStatus } 	from '../user/user.service'
import { CallkitService } 					from '../../wezeo/callkit/callkit.service'
import { SipService } 							from '../../wezeo/sip/sip.service'
import { WzoErrorService } 					from '../../wezeo/wzoError/wzoError.service'
import { ENV } 											from 'src/environments/environment'
import { WzoNotifications } 				from '../../wezeo/notifications/notifications.service'

declare const AudioToggle

export enum CallType {
	Incoming,
	Outgoing
}

export enum CallStatus {
	Calling,
	Dialing,
	Ringing,
	Idle,
}

export enum CallFlag {
	Muted,
	OnHold,
	LoudSpeaker,
	Transferred,
	Recording
}

@Injectable({
	providedIn: 'root'
})
export class CallService {

	_activeModal
	_audioLoudspeaker = false
	_ringtone = 'assets/sounds/ringing.mp3'
	_isVibratingAndRinging = false
	_vibrationInterval$
	_stopVibrationAndRinging$ = new EventEmitter()
	_canVibrateAndRing = false

	_callType: CallType
	_userStatus: UserStatus

	_flags = []

	_error = ''
	_internalError = ''

	_keypadValue = ''
	_dialingDuration
	_callStatus$ = new BehaviorSubject<CallStatus>(CallStatus.Idle)
	_callDuration$ = new BehaviorSubject<any>(0)
	_callDialing$ = new BehaviorSubject<any>(0)
	_callSubject$ = new BehaviorSubject<any>({})
	_caller

	@Output() _keypadDialEvent = new EventEmitter()
	@Output() _hangupCallEvent = new EventEmitter()
	@Output() _rejectCallEvent = new EventEmitter()
	@Output() _answerCallEvent = new EventEmitter()
	@Output() _transferCallEvent = new EventEmitter()

	constructor(
		private audio:							NativeAudio,
		private backgroundMode: 		BackgroundMode,
		private callingService:			CallingService,
		private callkitService: 		CallkitService,
		private callUtil:						CallUtil,
		private notifications:			WzoNotifications,
		private modalController: 		ModalController,
		// private ringtones:					NativeRingtones,
		private phoneUtil:					PhoneNumberUtilService,
		private platform:						Platform,
		private router: 						Router,
		private sipService: 				SipService,
		private userService: 				UserService,
		private vibration:					Vibration,
		private wzoErrorService:		WzoErrorService,
	) {

		this.userService.getUserStatus$().subscribe(status => this._userStatus = status)
		// this.ringtones.getRingtone().then((ringtone) => {
		// 	console.log(ringtone)
		// 	if (this.platform.is('android')) {
		// 		this._ringtone = ringtone.length && ringtone[0].Url
		// 	}
		// })

		this.platform.ready().then(() => {
			if (this.platform.is('cordova')) {
				this.audio.preloadComplex('cloudtalk_ringtone', this._ringtone, 1, 1, 0).then(() => {
					this.sipService.getIncomingCalls$().subscribe(call => this._handleIncomingCall(call))
				}).catch(error => {
					console.error(error)
				})
			} else {
				this.sipService.getIncomingCalls$().subscribe(call => this._handleIncomingCall(call))
			}
		})

		this.callkitService.answer$().subscribe(() => this.answerCall())
		this.callkitService.hangup$().subscribe(() => this.hangupCall())
		this.callkitService.reject$().subscribe(() => this.rejectCall())
	}

	answerCall() {
		this._callType = CallType.Incoming
		let call = this.sipService.accept()
		this.callkitService.connectCall()

		if (this.platform.is('cordova')) {
			this._stopVibrationAndRinging()
			call.mediaHandler.on('addStream', () => {
				AudioToggle.setAudioMode(AudioToggle.EARPIECE)
			})

			// hack?
			call.mediaHandler.on('iceCandidate', () => {
				AudioToggle.setAudioMode(AudioToggle.EARPIECE)
			})
		}

		this._callStatus$.next(CallStatus.Calling)
		this._answerCallEvent.emit()
		this.notifications.clearAllNotifications()
		this._handleByeCall(call)
	}

	async makeCall(number) {
		if (!await this.callUtil.arePermissionsGranted() || !this.callUtil.isOnline()) {
			return false
		}

		number = number.replace(/\s/g, '')
		if (this._callStatus$.getValue() != CallStatus.Idle) {
			return false
		}

    this.matchCallSubject(number)

		this._callType = CallType.Outgoing
		this._callStatus$.next(CallStatus.Dialing)
		this.addFlag(CallFlag.Recording)

		try {
			let call = this.sipService.invite(this._getSipUri(number))

			if (this.platform.is('cordova')) {
				call.mediaHandler.on('addStream', () => {
					AudioToggle.setAudioMode(AudioToggle.EARPIECE)
				})
			}

			this._handleAcceptedCall(call)
			this._handleFailedCall(call)
			this._handleByeCall(call)
			this._handleCallProgress(call)
		} catch (e) {
			alert(e)
		}

		return true
	}

	matchCallSubject(number) {

		let contactMatch = this.userService.userUtil.findContactByNumber(
			this.userService.getRawContacts(),
			number
		)

    this.changeCallSubject({
			id: contactMatch && contactMatch.id || null,
      name: contactMatch && contactMatch.name || '',
      number: this.phoneUtil.format(number, 'ZZ'),
      info: contactMatch && contactMatch.company || ''
    })
	}

	rejectCall() {
		this._endCall()
		this._rejectCallEvent.emit()
	}

	hangupCall() {
		this._endCall()
		this._hangupCallEvent.emit()
	}

	transferCall(number) {
		if (!this._isCalling()) {
			return
		}

		this._transferCallEvent.emit()
		this.sipService.transfer(this._getSipUri(number))
	}

	externalTransfer(number) {
		if (!this._isCalling()) {
			return
		}

		this._transferCallEvent.emit()
		this.sipService.externalTransfer(number)
	}

	toggleLoudspeaker() {
		if (this.platform.is('cordova')) {
			this._audioLoudspeaker = !this._audioLoudspeaker

			if (this._audioLoudspeaker) {
				AudioToggle.setAudioMode(AudioToggle.SPEAKER)
				AudioToggle.setSpeakerphoneOn(true)
			} else {
				AudioToggle.setAudioMode(AudioToggle.EARPIECE)
				AudioToggle.setSpeakerphoneOn(false)
			}
		}
	}

	toggleMute() {
		if (!this._isCalling()) {
			return
		}

		if (this.hasFlag(CallFlag.Muted)) {
			this.sipService.unmute()
			this.removeFlag(CallFlag.Muted)
			return
		}

		this.sipService.mute()
		this.addFlag(CallFlag.Muted)
	}

	toggleHold() {
		if (!this._isCalling()) {
			return
		}

		if (this.hasFlag(CallFlag.OnHold)) {
			this.sipService.unhold()
			this.removeFlag(CallFlag.OnHold)
			return
		}

		this.sipService.hold()
		this.addFlag(CallFlag.OnHold)
	}

	keypadDial(data) {
		this._keypadDialEvent.emit(data)
	}

	keypadDial$() {
		return this._keypadDialEvent
	}

	getKeypadValue() {
		return this._keypadValue
	}

	setKeypadValue(value) {
		this._keypadValue = value
	}

	sendKeypress(key) {
		this.sipService.dtmf(key)
	}

	hasFlag(flag: CallFlag) {
		return this._flags.indexOf(flag) > -1
	}

	addFlag(flag: CallFlag) {
		if (this.hasFlag(flag)) {
			return
		}

		this._flags.push(flag)
	}

	removeFlag(flag: CallFlag) {
		this._flags = this._flags.filter(f => f != flag)
	}

	getCallStatus$() {
		return this._callStatus$
	}

	getCallDuration$() {
		return this._callDuration$
	}

	getCallDialing$() {
		return this._callDialing$
	}

	getCallSubject$() {
		return this._callSubject$
	}

	changeCallSubject(subject) {
		this._callSubject$.next(subject)
	}

	callDurationTick(time) {
		this._callDuration$.next(time)
	}

	callDialingTick(time) {
		this._callDialing$.next(time)
	}

	callAnswered$() {
		return this._answerCallEvent
	}

	callRejected$() {
		return this._rejectCallEvent
	}

	callHungup$() {
		return this._hangupCallEvent
	}

	setCanVibrateAndRing(value) {
		this._canVibrateAndRing = value
	}

	getCanVibrateAndRing(value) {
		return this._canVibrateAndRing
	}

	_isCalling() {
		return this.getCallStatus$().getValue() == CallStatus.Calling
	}

	vibrateAndRing() {
		console.log('vibrateAndRing')
		console.log('vibrateAndRing', this._canVibrateAndRing)

		if (!this._canVibrateAndRing) {
			return
		}

		this.platform.ready().then(() => {
			console.log('vibrateAndRing', this.getCallStatus$().getValue())
			if (this._isVibratingAndRinging || this.getCallStatus$().getValue() != CallStatus.Ringing) {
				return
			}

			// if (!this._vibrationInterval$) {
			this._vibrationInterval$ = timer(0, 2000).pipe(
				takeUntil(this._stopVibrationAndRinging$)
			).subscribe(() => {
				this.vibration.vibrate(1000)
			})

			this.audio.loop('cloudtalk_ringtone')
			this._isVibratingAndRinging = true
			// }
		})
	}

	_stopVibrationAndRinging() {
		console.log('_stopVibrationAndRinging')
		console.log(this.getCallStatus$().getValue())

		/*
		if (this._isVibratingAndRinging || this.getCallStatus$().getValue() == CallStatus.Ringing)  {
			return
		}
		*/

		this._stopVibrationAndRinging$.emit()
		this.audio.stop('cloudtalk_ringtone')

		// if (this._vibrationInterval$) {
		// console.log(this._vibrationInterval$
		// this._vibrationInterval$.unsubscribe()
		// this._vibrationInterval$ = null
		// }

		// this.audio.stop('cloudtalk_ringtone')
		this._isVibratingAndRinging = false
		this._canVibrateAndRing = false
	}

	_handleIncomingCall(call) {
		console.log(call)

		if (
			(this._callStatus$.getValue() != CallStatus.Idle) ||
			(this._userStatus == UserStatus.Idle)
		) {
			return call.reject({
				statusCode: 486
			})
		}

		this._callStatus$.next(CallStatus.Ringing)

		let callSubjectNumber = call.remoteIdentity.uri.user
		let remoteIdentityInfo = call.remoteIdentity.displayName.split(' (via:')
		let callReceiverName = ''

		if (remoteIdentityInfo.length > 1) {
			callReceiverName = remoteIdentityInfo[1].slice(0, -1)
			this.callingService.getCallers$().subscribe(callers => {
				let callReceiver = callers.find(caller => caller.internal_name == callReceiverName)

				if (typeof callReceiver != 'undefined') {
					this._caller = this.callingService.getCaller$().getValue()
					this.callingService.changeCaller(callReceiver).subscribe()
				}
			})
		}

		// let callerName = call.remoteIdentity.displayName.split(' (via:')[0]
		// let callerInfo = callerName || callerNumber || 'Unknown'

		if (this.backgroundMode.isActive()) {
			if (this.platform.is('android')) {
				setTimeout(() => {
					this.backgroundMode.unlock()
				}, 1000)
			}
		}

		if (this.platform.is('cordova')) {
			this.vibrateAndRing()
		}

		this.matchCallSubject(callSubjectNumber)
		this._handleFailedCall(call)

		this.modalController.getTop().then(activeModal => {
			if (activeModal) {
				this._activeModal = activeModal
				activeModal.dismiss()
			}
		})

		this.router.navigate(['/call/ongoing'])
	}

	_handleFailedCall(call) {
		call.on('failed',
			(response, cause) => {
				// console.log(response.statusCode, response.status_code, response.reasonPhrase, response.reason_phrase, cause, '_handleFailedCall')

				if (!response) {
					this.rejectCall()
					return
				}

				const isCompletedElsewhere = (((((response || {}).headers || {}).Reason || {})[0] || {}).raw || null)

				const responseStatusCode = response.statusCode || response.status_code || null
				const statusCode = (cause && cause.status_code) || responseStatusCode
				const responseReasonPhrase = response.reasonPhrase || response.reason_phrase || null
				const reasonPhrase = (typeof cause == 'object' && cause.reason_phrase) || cause || responseReasonPhrase

				console.log(cause, statusCode, reasonPhrase, isCompletedElsewhere)

				if (reasonPhrase == SIP.C.REASON_PHRASE[480]) {
					// Temporarily Unavailable
					this.rejectCall()
				} else if (
					isCompletedElsewhere &&
					isCompletedElsewhere.toLowerCase().indexOf('call completed elsewhere') !== -1
				) {
					// handled by other operator
					this.wzoErrorService.addMessage({
						text: 'Call handled by other operator',
						type: 'warning'
					})
					this.hangupCall()
				} else if (reasonPhrase == SIP.C.causes.CANCELED) {
					// Canceled
					this.rejectCall()
				} else if (statusCode == 487 || cause == SIP.C.causes.CANCELED) {
					this.hangupCall()
				} else if (statusCode == 486) {
					this.rejectCall()
				} else {
					this._internalError = statusCode + ' ' + reasonPhrase
					this._error = this.callUtil.translateSipErrors(statusCode)
					this.wzoErrorService.addMessage({
						text: this._error,
						type: 'error'
					})
					this.hangupCall()
				}
			}
		)
	}

	_handleAcceptedCall(call) {
		call.on('accepted',
			() => {
				this._callStatus$.next(CallStatus.Calling)
				this._callDialing$.next(0)
				this.callkitService.connectCall()
				this._answerCallEvent.emit()
			}
		)
	}

	_handleByeCall(call) {
		call.on('bye', () => {
			this.hangupCall()
		})
	}

	_handleCallProgress(call) {
		call.on('progress',
			response => {
				if (
					response.status_code == 183 &&
					response.body &&
					call.hasOffer &&
					!call.dialog
				) {
	        if (
						!response.hasHeader('require') ||
						response.getHeader('require').indexOf('100rel') === -1
					) {
	          if (call.mediaHandler.hasDescription(response)) {
	            call.mediaHandler.setDescription(response)
	          }
	        }
	      }
			}
		)
	}

	_endCall() {
		this._callDuration$.next(0)
		this._callDialing$.next(0)

		if (this._caller) {
			timer(3000).subscribe(() => {
				this.callingService.changeCaller(this._caller).subscribe()
			})
		}
		this.notifications.clearAllNotifications()

		if (this.getCallStatus$().getValue() == CallStatus.Idle) {
			if (this.platform.is('cordova')) {
				console.log('endCall if status is idle')
				this._stopVibrationAndRinging()
				AudioToggle.setAudioMode(AudioToggle.SPEAKER)
			}
			return
		}

		if (this.getCallStatus$().getValue() == CallStatus.Calling) {
			this._callStatus$.next(CallStatus.Idle)
			this.sipService.bye()
		} else {
			const call = this.sipService.getCall()

			if (call && call.status == SIP.C.STATUS_EARLY_MEDIA) {
				this._callStatus$.next(CallStatus.Idle)
        this.sipService.bye()
      } else if (this.getCallStatus$().getValue() == CallStatus.Dialing) {
				this._callStatus$.next(CallStatus.Idle)
        this.sipService.cancel()
      } else {
				this._callStatus$.next(CallStatus.Idle)
        this.sipService.reject()
      }
		}

		if (this.platform.is('cordova')) {
			console.log('end call')
			this._stopVibrationAndRinging()
			AudioToggle.setAudioMode(AudioToggle.SPEAKER)
		}
		this.callkitService.endCall()
		this._flags = []
	}

	_getSipUri(number) {
		return `sip:${number}@${ENV.sip.dnsServer}`
	}

}

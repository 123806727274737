import { Component, OnInit, Input, ChangeDetectionStrategy } from '@angular/core'
import { Router } from '@angular/router'

import { ModalController } from '@ionic/angular'

import { CallService } from '../../call/call.service'
import { CtCallDetailModal } from '../call-detail-modal/call-detail-modal.component'
import { CallHistoryUtil } from 'src/app/pages/call/history/call-history.util'

@Component({
  selector: 'ct-call-detail',
  templateUrl: './call-detail.component.html',
  styleUrls: ['./call-detail.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CtCallDetail implements OnInit {
  
  @Input() call
  @Input() user
  
  callStatus
  callDetailModal

  constructor(
    private callService:      CallService,
    private callHistoryUtil:  CallHistoryUtil,
    private modalController:  ModalController,
    private router:           Router,
  ) { }

  ngOnInit() {}
  
  async showDetail() {
    
    this.callDetailModal = await this.modalController.create({
      cssClass: 'ct-modal',
      component: CtCallDetailModal,
      componentProps: { callDetail: this.call }
    })
    
    return await this.callDetailModal.present()
  }
  
  async startCall() {
    if (await this.callService.makeCall(this.call.numbers.fullNumber)) {
      this.router.navigate(['/call/ongoing'])
    }
  }
  
  callType() {
    return this.callHistoryUtil.getCallGroup(this.call)
  }

}

import {
  ErrorHandler,
  NgModule,
  LOCALE_ID
}                                   from '@angular/core'
import { BrowserModule }            from '@angular/platform-browser'
import { RouteReuseStrategy }       from '@angular/router'
import {
  HttpClient,
  HttpClientModule
}                                   from '@angular/common/http'

import {
  IonicModule,
  IonicRouteStrategy
}                                   from '@ionic/angular'
import { BackgroundMode }           from '@ionic-native/background-mode/ngx'
import { HTTP }                     from '@ionic-native/http/ngx'
import { InAppBrowser }             from '@ionic-native/in-app-browser/ngx'
import { NativeAudio }              from '@ionic-native/native-audio/ngx'
import { NativeRingtones }          from '@ionic-native/native-ringtones/ngx'
import { Network }                  from '@ionic-native/network/ngx'
import { Push }                     from '@ionic-native/push/ngx'
import { ScreenOrientation }        from '@ionic-native/screen-orientation/ngx'
import { SplashScreen }             from '@ionic-native/splash-screen/ngx'
import { StatusBar }                from '@ionic-native/status-bar/ngx'
import { Vibration }                from '@ionic-native/vibration/ngx'
import {
  TranslateModule,
  TranslateLoader
}                                   from '@ngx-translate/core'

import { AppComponent }             from './app.component'
import { AppRoutingModule }         from './app-routing.module'

import { CallUtil }                 from './pluginz/cloudtalk/call/call.util'
import { CtCaller }                 from './pluginz/cloudtalk/ui-components/ct-caller/ct-caller.component'
import { CtCallAction }             from './pluginz/cloudtalk/ui-components/call-action/call-action.component'
import { CtCallDetail }             from './pluginz/cloudtalk/ui-components/call-detail/call-detail.component'
import { CtCallDetailModal }        from './pluginz/cloudtalk/ui-components/call-detail-modal/call-detail-modal.component';
import { CtFlashMessage }           from './pluginz/cloudtalk/ui-components/ct-flash-message/ct-flash-message.component'
import { InternationalPrefixModal } from './pluginz/cloudtalk/ui-components/international-prefix-modal/international-prefix-modal.component'
import { CldCallingNumberModal }    from './pluginz/cloudtalk/ui-components/calling-number-modal/calling-number-modal.component'
import { CtCallTransferModal }      from './pluginz/cloudtalk/ui-components/call-transfer-modal/call-transfer-modal.component'
import { CtCallIncomingModal }      from './pluginz/cloudtalk/ui-components/call-incoming-modal/call-incoming-modal.component'
import { CldUserStatus }            from './pluginz/cloudtalk/ui-components/user-status/user-status.component'
import { CldUserStatusPopover }     from './pluginz/cloudtalk/ui-components/user-status-popover/user-status-popover.component'
import { CtCallNote }               from './pluginz/cloudtalk/ui-components/call-note-modal/call-note-modal.component'
import { CtCallTags }               from './pluginz/cloudtalk/ui-components/call-tags-modal/call-tags-modal.component'
import { CtKeypadModal }              from './pluginz/cloudtalk/ui-components/ct-keypad-modal/ct-keypad-modal.component'
import { Settings }                 from './pluginz/cloudtalk/ui-components/settings-modal/settings-modal.component'
import { SettingsInput }            from './pluginz/cloudtalk/ui-components/settings-input-modal/settings-input-modal.component'
import { SettingsOptions }          from './pluginz/cloudtalk/ui-components/settings-options-modal/settings-options-modal.component'
import { CloudtalkSharedModule }    from './pluginz/cloudtalk/shared/shared.module'
import { CallService }              from './pluginz/cloudtalk/call/call.service'
import { PhoneNumberUtilService }   from './pluginz/cloudtalk/call/phonenumber.util'
import { UserUtil }                 from './pluginz/cloudtalk/user/user.util'

import { CallkitService }           from './pluginz/wezeo/callkit/callkit.service'
import { SipService }               from './pluginz/wezeo/sip/sip.service'
import { TranslateFactory }         from './pluginz/wezeo/translate/translate.factory'
import { WzoHttp }                  from './pluginz/wezeo/wzoHttp/wzoHttp.service'
import { WzoHttpUtil }              from './pluginz/wezeo/wzoHttp/wzoHttp.util'
import { WzoOfflineService }        from './pluginz/wezeo/offline/wzo-offline.service'
import { WzoSentry }                from './pluginz/wezeo/sentry/wzo-sentry'

// Wezeolytics
import { JsErrorHandler }             from './pluginz/wezeo/wezeolytics/features/js.errorHandler'
import { WezeolyticsBehavior }        from './pluginz/wezeo/wezeolytics/wezeolytics-behavior'
import { WezeolyticsService }         from './pluginz/wezeo/wezeolytics/wezeolytics.service'
import { CloudtalkWezeolyticsService } from './pluginz/cloudtalk/analytics/cloudtalk-wezeolytics.service'


@NgModule({
  declarations: [
    AppComponent
  ],
  entryComponents: [
    CtCaller,
    CtCallAction,
    CtCallDetail,
    CtCallDetailModal,
    CtFlashMessage,
    CldCallingNumberModal,
    CtCallTransferModal,
    CtCallIncomingModal,
    CldUserStatus,
    CldUserStatusPopover,
    InternationalPrefixModal,
    CtCallNote,
    CtCallTags,
    CtKeypadModal,
    Settings,
    SettingsOptions,
    SettingsInput
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    IonicModule.forRoot({
      animated: false,
      mode:     'ios',
    }),
    AppRoutingModule,

    TranslateModule.forRoot({
      loader: {
        provide:    TranslateLoader,
				useFactory: TranslateFactory,
				deps:       [HttpClient]
			}
    }),

    CloudtalkSharedModule,
  ],
  providers: [
    // native
    BackgroundMode,
    HTTP,
    InAppBrowser,
    NativeAudio,
    NativeRingtones,
    Network,
    Push,
    ScreenOrientation,
    StatusBar,
    SplashScreen,
    Vibration,

    // cloudtalk
    CallService,
    CallUtil,
    PhoneNumberUtilService,
    UserUtil,

    // wezeo
    CallkitService,
    SipService,
    WzoHttp,
    WzoHttpUtil,
    WzoOfflineService,

    // wezeolytics
    WezeolyticsService,
    WezeolyticsBehavior,
    // CloudtalkWezeolyticsService,
    {
      provide: RouteReuseStrategy,
      useClass: IonicRouteStrategy
    },
    {
      provide: LOCALE_ID,
      useValue: 'en'
    },
    {
      provide: ErrorHandler,
      useClass: WzoSentry
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor(
    // private cloudtalkAnalytics: CloudtalkWezeolyticsService
  ) {

  }
}

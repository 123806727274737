import { Injectable, EventEmitter, Output } from '@angular/core'
import { Platform } from '@ionic/angular'

declare const cordova

@Injectable({
  providedIn: 'root'
})
export class CallkitService {
  
  @Output() _answerEvent = new EventEmitter()
  @Output() _hangupEvent = new EventEmitter()
  @Output() _rejectEvent = new EventEmitter()
  
  _plugin

  constructor(
    private platform: Platform
  ) { 
    this.platform.ready().then(() => {
      if (!this.platform.is('cordova')) {
        return
      }
      
      this._plugin = cordova.plugins.CordovaCall
      
      this._plugin.on('answer', () => {
        this._answerEvent.emit()
      })
      
      this._plugin.on('hangup', () => {
        this._hangupEvent.emit()
      })
      
      this._plugin.on('reject', () => {
        this._rejectEvent.emit()
      })
    })
  }
  
  answer$() {
    return this._answerEvent
  }
  
  hangup$() {
    return this._hangupEvent
  }
  
  reject$() {
    return this._rejectEvent
  }
  
  connectCall() {
    if (!this._plugin) {
      return
    }
    
    this._plugin.connectCall()
  }
  
  receiveCall(name) {
    if (!this._plugin) {
      return
    }
    
    return this._plugin.receiveCall(name, () => {}, () => {})
  }
  
  endCall() {
    if (!this._plugin) {
      return
    }
    
    this._plugin.endCall()
  }
  
}

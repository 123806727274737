import { Component, OnInit, Input, HostListener } from '@angular/core'

@Component({
  selector: 'ct-call-action',
  templateUrl: './call-action.component.html',
  styleUrls: ['./call-action.component.scss'],
})
export class CtCallAction implements OnInit {
  
  @Input() action
  @Input() icon
  @Input() name
  @Input() activable
  
  active = false

  constructor() { }

  ngOnInit() { }
  
  @HostListener('click')
  onClick() {
    this.action()
    
    if (this.activable) {
      this.active = !this.active
    }
  }

}

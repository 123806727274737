import {
  Component,
  OnInit,
  OnDestroy
}                                   from '@angular/core'

import { PopoverController }        from '@ionic/angular'
import { Subscription }             from 'rxjs'

import { UserStatus, UserService }  from '../../user/user.service'
import { AuthService }              from '../../user/auth.service'

@Component({
  selector: 'app-user-status-popover',
  templateUrl: './user-status-popover.component.html',
  styleUrls: ['./user-status-popover.component.scss'],
})
export class CldUserStatusPopover implements OnInit, OnDestroy {

  userStatus = UserStatus
  user = {}

  _getUserSubscription$: Subscription

  constructor(
    public popoverController: PopoverController,

    private authService:      AuthService,
    private userService:      UserService,
  ) {}

  ngOnInit() {
    this._getUserSubscription$ = this.userService.getUser$().subscribe(user => this.user = user)
  }

  ngOnDestroy() {
    this._getUserSubscription$.unsubscribe()
  }

  async presentPopover(event) {
    const popover = await this.popoverController.create({
      cssClass: 'ct-popover ct-popover--userStatus',
      component: CldUserStatusPopover,
      event: event,
      translucent: false
    })
    return await popover.present()
  }

  closePopover() {
    this.popoverController.dismiss()
  }

  changeUserStatus(status) {
    this.userService.changeUserStatus(status)
    this.closePopover()
  }

  DEV_logout() {
    this.authService.logout()
    this.closePopover()
  }

}

import {
  Injectable,
  Output,
  EventEmitter
}                           from '@angular/core'

import { NavController }    from '@ionic/angular'
import { map }              from 'rxjs/operators'

import { ENV }              from 'src/environments/environment'
import { AuthApi }          from './auth.api'
import { WzoHttpAuth }      from '../../wezeo/wzoHttp/wzoHttpAuth.service'
import { SipService }       from '../../wezeo/sip/sip.service'

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  @Output() _login$ = new EventEmitter()
  @Output() _logout$ = new EventEmitter()

  _tokenName = `${ENV.appPrefix}_authToken`

  constructor(
    private authApi:      AuthApi,
    private nav:          NavController,
    private sipService:   SipService,
    private wzoHttpAuth:  WzoHttpAuth,
  ) {
    let authToken = this.getAuthToken()
    if (authToken) {
      this._setAuthToken(authToken)
    }
  }

  logout$() {
    return this._logout$
  }

  login$() {
    return this._login$
  }

  login(email, password) {
    return this.authApi.login(email, password).pipe(
      map(
        resp => {
          if (!resp['success']) {
            return resp
          }

          this._setAuthToken(resp.data.token)
          this.setUserAndConnectToSip(resp.data.token)

          return resp
        }
      )
    )
  }

  logout() {
    this._clearAuthToken()
    this._logout$.emit()
    this.sipService.disconnect()

    this.nav.navigateRoot(['/account/login'])
    // return this.authApi.logout().pipe(
    //   map(
    //     resp => {
    //       if (!resp['success']) {
    //         return resp
    //       }
    //
    //       this._logout$.emit()
    //       return resp
    //     }
    //   )
    // )
  }

  sipAuth() {
    return this.authApi.sipAuth().pipe(
      map(
        resp => {
          if (!resp['success']) {
            return resp
          }

          const data = resp['data']
          this.sipService.connect(data.name, data.password)

          return data
        }
      )
    )
  }

  getAuthToken() {
    return window.localStorage.getItem(this._tokenName)
  }

  setUserAndConnectToSip(token) {
    let user = this._parseToken(token)

    this.sipAuth().subscribe()
    this._login$.emit(user)
  }

  reregisterSip$() {
    console.log('reregister')
    this.sipService.unregister()
    return this.sipAuth()
  }

  _clearAuthToken() {
    window.localStorage.removeItem(this._tokenName)
  }

  _setAuthToken(token) {
    window.localStorage.setItem(this._tokenName, token)
    this.wzoHttpAuth.setAuthToken(token)
  }

  _parseToken(token) {

    let base64Url = token.split('.')[1]
    let base64 = base64Url.replace('-', '+').replace('_', '/')
    return JSON.parse(window.atob(base64))
  }

}

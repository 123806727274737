import { Injectable }   from '@angular/core'
import { HttpHeaders }  from '@angular/common/http'

import { Platform }     from '@ionic/angular'

import { AlertUtil }    from '../alert/alert.util'
import { ENV }          from '../../../../environments/environment'

@Injectable({
  providedIn: 'root'
})
export class WzoHttpUtil {
  
  constructor(
    private alert:    AlertUtil,
    private platform: Platform,
  ) {}

  // podla ingoreRules z interceptoru  vyhodnoti, ci odchytit request
  shouldSkipRequest(req, ignoreRules) {
    for (let rule of ignoreRules) {
      if (!rule.subroutes || rule.subroutes == '*' || rule.subroutes.length == 0) {
        if (req.url.indexOf(rule.route) !== -1) {
          return true
        }

        continue
      }

      for (let subroute of rule.subroutes) {
        if (req.url.indexOf(subroute) !== -1) {
          return true
        }
      }
    }

    return false
  }

  wrapError = (error) => {
    if (typeof error !== 'object') {
      return ['Server error']
    }

    console.error(error)

    if (!error.error) {
      this.alert.error(error)
      return this.platform.is('cordova') ? [JSON.parse(error)] : [error]
    }

    // this.alert.error(error.error)
    return this.platform.is('cordova') ? [JSON.parse(error.error)] : [error.error]
  }

  getQueryStringFromParams(params) {
    let queryString = ''

    if (Object.keys(params).length) {
      queryString += '?'
      for (let [paramName, paramValue] of params) {
        queryString += `${paramName}=${paramValue}&`
      }
      queryString = queryString.slice(0, -1)
    }

    return queryString
  }

  url(url) {
    if (!url.startsWith('http') && !url.startsWith('/')) {
      url = ENV.http.API_PROTOCOL + ENV.http.API_HOST + ENV.http.API_VERSION + url
    }

    return url
  }

  options(options) {

    options = options || {}
    options.headers = options.headers || new HttpHeaders(ENV.http.headers)
    return options
  }
}

import { Injectable } from '@angular/core'

import { catchError } from 'rxjs/operators'

import { WzoHttp } from './wzoHttp.service'
import { WzoHttpUtil } from './wzoHttp.util'
import { ENV } from '../../../../environments/environment'

@Injectable({
  providedIn: 'root'
})
export class WzoHttpAuth {
  
  _token
  defaultHeaders = Object.assign({}, ENV.http.headers)

  constructor(
    public wzoHttp: WzoHttp,
    public util: WzoHttpUtil
  ) {
    
  }

  get(url, options?) {
    url = this._token ? url + `?token=${this._token}` : url
    
    return this.wzoHttp.get(url, this.setAuthHeaders(options)).pipe(
      catchError(this.util.wrapError)
    )
  }

  post(url, data, options?) {
    url = this._token ? url + `?token=${this._token}` : url
    
    return this.wzoHttp.post(url, data, this.setAuthHeaders(options))
  }

  put(url, data, options?) {
    return this.wzoHttp.put(url, data, this.setAuthHeaders(options))
  }

  delete(url, options?) {
    return this.wzoHttp.delete(url, this.setAuthHeaders(options))
  }

  getPlainUrl(url) {
    url = this._token ? url + `?token=${this._token}` : url
    url = this.util.url(url)
    return url
  }

  setAuthHeaders(options) {
    options = options || {}
    
    options = {
      ...options,
      'headers': {
        ...this.defaultHeaders,
        ...options.headers
      }
    }

    return options
  }

  setAuthToken(authToken) {
    
    this._token = authToken
    // this.defaultHeaders['Authorization'] = 'Bearer ' + authToken

  }

  clearAuthToken() {

    delete this.defaultHeaders['Authorization']
  }
}

import {
  Component,
  OnInit,
  OnDestroy,
}                             from '@angular/core'

import { ModalController }    from '@ionic/angular'
import { timer, Subscription }              from 'rxjs'
import { finalize }           from 'rxjs/operators'

import { CallService }        from '../../call/call.service'
import { CallingService }     from '../../call/calling.service'
import { UserService }        from '../../user/user.service'
import { UserUtil }           from '../../user/user.util'

@Component({
  selector: 'ct-call-transfer-modal',
  templateUrl: './call-transfer-modal.component.html',
  styleUrls: ['./call-transfer-modal.component.scss'],
})
export class CtCallTransferModal implements OnInit, OnDestroy {

  agents = []
  // callSubject
  filteredAgents = []
  transferNumberPrefix = ''
  isInitialCountryChange = true
  transferNumber
  searchQuery = ''
  inputValue = ''
  transferList = []
  transferInProgress = false
  user

  _agentsSubscription$: Subscription
  _getUserSubscription$: Subscription
  _getCountrySubscription$: Subscription

  constructor(
    private callService:     CallService,
    private callingService:  CallingService,
    private modalController: ModalController,
    private userService:     UserService,
    private userUtil:        UserUtil,
  ) { }

  ngOnInit() {
    this._agentsSubscription$ = this.userService.agents().subscribe(
      agents => {
        this.agents = agents
        // console.log(this.agents)
        this.filteredAgents = this.userService.getAgents()
        // console.log(this.filteredAgents)
      }
    )
    this._getUserSubscription$ = this.userService.getUser$().subscribe(user => this.user = user)
    this._getCountrySubscription$ = this.callingService.getCountry$().subscribe(country => {
      if (this.isInitialCountryChange) {
        this.isInitialCountryChange = false
        return
      }

      if (!country || !country.calling_code) {
        return
      }

      if (!this.transferNumberPrefix.toString().length) {
        this.searchQuery = `+${country.calling_code}${this.searchQuery}`
      } else {
        this.searchQuery = this.searchQuery.replace(`+${this.transferNumberPrefix}`, `+${country.calling_code}`)
      }

      this.transferNumberPrefix = country.calling_code
      this.search()
    })
    // this.callService.getCallSubject$().subscribe(callSubject => this.callSubject = callSubject)
  }

  ngOnDestroy() {
    this._agentsSubscription$.unsubscribe()
    this._getUserSubscription$.unsubscribe()
    this._getCountrySubscription$.unsubscribe()
  }

  async presentModal(data) {

    const modal = await this.modalController.create({
      cssClass: 'ct-modal',
      component: CtCallTransferModal,
      componentProps: { ...data }
    })

    return await modal.present()
  }

  closeModal = () => {
    return this.modalController.dismiss()
  }

  externalTransfer() {
    this.callService.externalTransfer(this.searchQuery.replace('+', '00'))
    this._startCloseModalTimer()
  }

  search() {
    this.filteredAgents = this.userUtil.mapAgents(
      this.agents.filter(
        agent => {
          return this._removeAccents(
            agent.display_name.toLowerCase()
          ).indexOf(
            this._removeAccents(this.searchQuery.toLowerCase())
          ) != -1
        }
      )
    )
  }

  transferCall(agent) {
    if (agent.online_status == 'offline') {
      // TODO: add error message, e.g. 'Agent is offline, transfer is not possible'
      return
    }

    if (this.user.id == agent.id) {
      // TODO: add error message, e.g. 'Cannot transfer call to yourself'
      // TODO: or remove logged agents from transfer list
      return
    }

    this.callService.transferCall(agent.extension)
    this._startCloseModalTimer()
  }

  _startCloseModalTimer() {
    this.transferInProgress = true

    timer(3000).pipe(
      finalize(() => {
        this.closeModal().then(() => {
          this.transferInProgress = false
        })
      })
    ).subscribe()
  }

  _removeAccents(string) {
    if (typeof String.prototype.normalize === 'function') {
      // prevedie string na Unicode normalizaciu a vyhodi specialne znaky - interpunkciu a pod.
      return string.normalize('NFD').replace(/[\u0300-\u036f]/g, '')
    }

    return string
  }

  MOCK_contacts() {
    return [
      {
        letter: 'A',
        contacts: [
          {
            name: 'Advertising',
            box_number: '042',
            department: '',
            group: true
          },
          {
            name: 'Alita B. Angel',
            box_number: '1099',
            department: 'Rollerblading',
            group: false
          }
        ]
      },
      {
        letter: 'B',
        contacts: [
          {
            name: 'Bilbo Baggins',
            box_number: '1001',
            department: 'Tech. support',
            group: false
          },
          {
            name: 'Chandler Bing',
            box_number: '1012',
            department: 'Advertising',
            group: false
          }
        ]
      }
    ]
  }

}

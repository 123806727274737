import { ModalController } from '@ionic/angular';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'ct-settings-options-modal',
  templateUrl: './settings-options-modal.component.html',
  styleUrls: ['./settings-options-modal.component.scss'],
})
export class SettingsOptions implements OnInit {

  constructor(public modalController: ModalController) { }

  ngOnInit() {}

  async presentModal(data) {
    
    const modal = await this.modalController.create({
      cssClass: 'ct-modal',
      showBackdrop: false,
      component: SettingsOptions
    })
    
    return await modal.present()
  }

  closeModal = () => {
    return this.modalController.dismiss()
  }

}

import {
  Component,
  OnInit,
  OnDestroy
}                                   from '@angular/core'

import { ModalController }          from '@ionic/angular'
import { Subscription }             from 'rxjs'

import { InternationalPrefixModal } from '../international-prefix-modal/international-prefix-modal.component'
import { CallingService }           from '../../call/calling.service'
import { UserService }              from '../../user/user.service'

@Component({
  selector: 'ct-flag-call-prefix',
  templateUrl: './ct-flag-call-prefix.component.html',
  styleUrls: ['./ct-flag-call-prefix.component.scss'],
})
export class CtFlagCallPrefix implements OnInit, OnDestroy {

  country
  countries

  _getCountrySubscription$: Subscription
  _getCountriesSubscription$: Subscription

  constructor(
    private callingService:   CallingService,
    private modalController:  ModalController,
    private userService:      UserService
  ) { }

  ngOnInit() {
    this._getCountrySubscription$ = this.callingService.getCountry$().subscribe(country => {
      if (!country) {
        return
      }

      this.country = country
    })
    this._getCountriesSubscription$ = this.userService.getCountries$().subscribe(
      countries => this.countries = countries
    )
  }

  ngOnDestroy() {
    this._getCountrySubscription$.unsubscribe()
    this._getCountriesSubscription$.unsubscribe()
  }

  async selectInternationalPrefix() {

    let selectInternationalPrefixModal = await this.modalController.create({
      animated: true,
      cssClass: 'ct-modal ct-modal--partial',
      component: InternationalPrefixModal,
      componentProps: { countries: this.countries }
    })

    return await selectInternationalPrefixModal.present()
  }

}

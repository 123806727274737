import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { SettingsInput } from 'src/app/pluginz/cloudtalk/ui-components/settings-input-modal/settings-input-modal.component'
import { SettingsOptions } from 'src/app/pluginz/cloudtalk/ui-components/settings-options-modal/settings-options-modal.component'

@Component({
  selector: 'ct-settings-modal',
  templateUrl: './settings-modal.component.html',
  styleUrls: ['./settings-modal.component.scss'],
})
export class Settings implements OnInit {

  constructor(public modalController: ModalController) { }

  ngOnInit() {}

  async showSettingsInput() {
    
    const modal = await this.modalController.create({
      cssClass: 'ct-modal',
      showBackdrop: false,
      component: SettingsInput
    })
    
    return await modal.present()
  }

  async showSettingsOptions() {
    
    const modal = await this.modalController.create({
      cssClass: 'ct-modal',
      showBackdrop: false,
      component: SettingsOptions
    })
    
    return await modal.present()
  }

  closeModal = () => {
    return this.modalController.dismiss()
  }

}

import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'ct-settings-input-modal',
  templateUrl: './settings-input-modal.component.html',
  styleUrls: ['./settings-input-modal.component.scss'],
})
export class SettingsInput implements OnInit {

  constructor(public modalController: ModalController) { }

  ngOnInit() {}

  async presentModal() {
    
    const modal = await this.modalController.create({
      cssClass: 'ct-modal',
      showBackdrop: false,
      component: SettingsInput
    })
    
    return await modal.present()
  }

  closeModal = () => {
    return this.modalController.dismiss()
  }

}

import { Component, OnInit, Input } from '@angular/core'
import { CallingService } from '../../call/calling.service';

@Component({
  selector: 'ct-caller-item',
  templateUrl: './ct-caller-item.component.html',
  styleUrls: ['./ct-caller-item.component.scss'],
})
export class CtCallerItem implements OnInit {

  @Input() caller

  constructor(
    private callingService: CallingService
  ) { }

  ngOnInit() {}

  callerRegion(caller) {
    return this.callingService.callerRegion(caller)
  }

  callerNumber(caller) {
    return this.callingService.callerNumber(caller)
  }
}

import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'ct-call-tags-modal',
  templateUrl: './call-tags-modal.component.html',
  styleUrls: ['./call-tags-modal.component.scss'],
})
export class CtCallTags implements OnInit {
  
  searchQuery

  constructor(public modalController: ModalController) { }

  ngOnInit() {}

  closeModal = () => {
    return this.modalController.dismiss()
  }

  search() {
    
  }
}

import { Directive, ElementRef, HostListener } from '@angular/core'

import { WezeolyticsBehavior } from '../../wezeolytics-behavior'

@Directive({
  selector: '[routerLink]'
})
export class RouterlinkDirective {

  constructor(
    private el: ElementRef,
    private wezeolyticsBehavior: WezeolyticsBehavior
  ) {}

  @HostListener('click') onClick() {
    let path = `(${this.wezeolyticsBehavior.getElementPath(this.el.nativeElement)})`

    this.wezeolyticsBehavior.event(
      'router link',
      `${this.el.nativeElement.textContent} ${path}`,
      ''
    )
  }

}

import { NgModule }             from '@angular/core'
import { 
  Routes, 
  RouterModule, 
  PreloadAllModules }           from '@angular/router'

import { UserLoggedInGuard }    from './pluginz/cloudtalk/user/user.guard'

const routes: Routes = [
  { 
    path:         '', 
    redirectTo:   'account', 
    pathMatch:    'full' 
  },
  { 
    path:         'account', 
    loadChildren: './pages/account/account.module#AccountModule' 
  },
  {
    path:         'call',
    loadChildren: './pages/call/call.module#CallModule',
    canActivate:  [UserLoggedInGuard]
  },
  { 
    path:         'contacts', 
    loadChildren: './pages/contacts/contacts.module#ContactsModule',
    canActivate:  [UserLoggedInGuard] 
  },
]

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      useHash: true,
      preloadingStrategy: PreloadAllModules,
    })
  ],
  exports:    [RouterModule],
  providers:  [UserLoggedInGuard]
})
export class AppRoutingModule { }

import { Injectable } 		from '@angular/core'
import { 
	CanActivate, 
	RouterStateSnapshot, 
	ActivatedRouteSnapshot 
} 												from '@angular/router'

import { NavController } 	from '@ionic/angular'

import { AuthService } 		from './auth.service'
import { UserService } 		from './user.service'

@Injectable()
export class UserLoggedInGuard implements CanActivate {
	
	constructor(
		private authService: 	AuthService,
		private userService:	UserService,
		private nav:					NavController,
	) {}
	
	canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
		
		let authToken = this.authService.getAuthToken()
		
		if (!authToken) {
			return this.nav.navigateRoot('/account/login').then(() => false)
		}
		
		if (!Object.keys(this.userService.getUser$().getValue()).length) {
			this.authService.setUserAndConnectToSip(authToken)
		}
		
		return true
	}
}

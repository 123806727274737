import { Injectable, NgZone } from '@angular/core'

import { ENV } from '../../../../../environments/environment'

@Injectable({
  providedIn: 'root'
})
export class GoogleAnalyticsIntegration {
  window = (<any>window)

  constructor(
    private zone: NgZone
  ) {
    let googleId = ENV.wezeolytics.googleAnalyticsID || 'UA-129182649-1'

    this.zone.runOutsideAngular(() => {
      this.window.ga('create', googleId, 'auto')
    })
  }

  send(category, action, label, route) {
    this.zone.runOutsideAngular(() => {
      this.window.ga('set', `dimension1`, route)
      this.window.ga('send', 'event', {
        eventCategory: category,
        eventAction: action,
        eventLabel: label
      })
    })
  }

  page(router) {
    this.zone.runOutsideAngular(() => {
      this.window.ga('set', 'page', router.url)
      this.window.ga('send', 'pageview')
    })
  }


}

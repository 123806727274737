import { Injectable }               from '@angular/core'

import { from, of, combineLatest }  from 'rxjs'
import { map,  }                    from 'rxjs/operators'
import { Platform }                 from '@ionic/angular'

import { NotificationsNativeApi }   from './integrations/native.api'
import { WebNotifications }         from './integrations/web.api'
import { NotificationsBackendApi }  from './notifications.api'

@Injectable({
  providedIn: 'root'
})
export class WzoNotifications {

  _topic
  _token
  _platformNotifications

  constructor(
    private nativeNotifications:  NotificationsNativeApi,
    private platform:             Platform,
    private webNotifications:     WebNotifications,

    public backendApi:            NotificationsBackendApi,
  ) {
    if (this.platform.is('cordova')) {
      this._platformNotifications = this.nativeNotifications
      return
    }

    this._platformNotifications = this.webNotifications
  }

  register$(handle) {
    return combineLatest(
      this._platformNotifications.register$(this._handleRegistration),
      this._onNotification$(handle)
    )

    ///////////////////////////////////////////////////////////////////////////////////////////
    // return this._platformNotifications.register().pipe(
    //   map(
    //     token => {
    //       this._saveTokenAndRegisterDevice(token)
    //         .then(resp    => this._handleApiError(resp))
    //         .then(()      => this._onNotification(handle))
    //         .catch(error  => {
    //           console.error(error)
    //           return null
    //         })
    //     }
    //   )
    // )
  }

  _handleRegistration = async (token) => {
    try {
      const resp = await this._saveTokenAndRegisterDevice(token)
      await this._handleApiError(resp)
    } catch (error) {
      console.error(error)
      return null
    }
  }

  _onNotification$(handle) {
    return this._platformNotifications.onNotification$().pipe(
      map(
        notification => {
          console.log(notification)
          handle(notification)
          return notification
        }
      )
    )
  }

  _getPlatformName() {
    let platformName
    if (this.platform.is('android')) {
      platformName = 'android'
    } else if (this.platform.is('ios')) {
      platformName = 'ios'
    }

    return platformName
  }

  _saveTokenAndRegisterDevice = (token) => {
    this._token = token
    console.log(token)

    return this.backendApi.registerDevice(token, this._getPlatformName()).toPromise()
  }

  _handleApiError(resp) {
    // TODO
    if (!resp['success']) {
      console.error(resp)
      return Promise.reject('API error')
    }

    return
  }

  handleTokenRefresh$() {
    return this._platformNotifications.handleTokenRefresh$().pipe(
      map(
        async token => {
          console.log('refreshed token', token, 'current token', this._token)
          if (token == this._token) {
            return of(null)
          }

          return from(
            this.backendApi.unregisterDevice(this._token).toPromise()
              .then(
                () => this.backendApi.registerDevice(token, this._getPlatformName()
              ).toPromise()
            )
          )
        }
      )
    )
  }

  unregister() {
    return this._platformNotifications.unregister().then(() => {
      this.backendApi.unregisterDevice(this._token)
    })
  }

  subscribeToTopic = topic => {
    this._topic = topic

    return this._platformNotifications.subscribeToTopic(this._topic)
  }

  unsubscribeFromTopic = () => {
    return this._platformNotifications.unsubscribeFromTopic(this._topic)
  }

  clearAllNotifications() {
    return this._platformNotifications.clearAllNotifications()
      .then(success => console.log('clear notifications success', success))
      .catch(error => console.error('clear notificatins error', error))
  }
}

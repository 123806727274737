import { EventEmitter, Injectable } from '@angular/core'
import { VoiceProviderService } from './voice-provider.service'

@Injectable({
    providedIn: 'root'
})
export class VoiceSettingService {
  private cookieName = 'cldtk-voice'
  private _audioInput: string = null
  private _audioOutput: string = null
  private _audioSoundOutput: string = null
  public mediaAcquired: EventEmitter<boolean> = new EventEmitter()

  constructor(private voiceProvider: VoiceProviderService) {
    this.audioOutput = null
    this.audioSoundOutput = null
    this.audioInput = null
  }

  get audioInput(): string {
    return this._audioInput
  }

  set audioInput(value: string) {
    this._audioInput = value
  }

  get audioSoundOutput(): string {
    return this._audioSoundOutput
  }

  set audioSoundOutput(value: string) {
    this._audioSoundOutput = value
    this.attachSinkId(this.voiceProvider.audioSound, this.audioSoundOutput)
  }

  /**
   * Audio output pre hovory
   * @return {string}
   */
  get audioOutput(): string {
    return this._audioOutput
  }

  set audioOutput(value: string) {
    this._audioOutput = value
    this.attachSinkId(this.voiceProvider.audio, this.audioOutput)
  }

  toString() {
    return JSON.stringify({
      audioInput: this._audioInput,
      audioOutput: this._audioOutput,
      audioSoundOutput: this._audioSoundOutput
    })
  }

  save() {
    // this.cookieService.setCookie(this.cookieName, this.toString(), 365 * 10)
  }

  /**
   * Attach audio output device to video element using device/sink ID.
   * @param element
   * @param output
   */
  attachSinkId(element, output) {
    if (typeof element.sinkId !== 'undefined') {
      element.setSinkId(output)
        .then(() => {
        })
        .catch((error) => {
        })
    } else {
      console.warn('Browser does not support output device selection.')
    }
  }

  public checkMedia() {
    const audioSource = this.audioInput || null
    let constr
    if (audioSource) {
      constr = {
        audio: {deviceId: audioSource ? {exact: audioSource} : undefined},
        video: false
      }
    } else {
      constr = {
        audio: true,
        video: false
      }
    }

    navigator.mediaDevices.getUserMedia(constr)
      .then(stream => {
        this.mediaAcquired.emit(true)
      })
      .catch(e => this.mediaAcquired.emit(false))
  }
}

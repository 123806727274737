import { Directive, ElementRef, HostListener } from '@angular/core'

import { WezeolyticsBehavior }  from '../../wezeolytics-behavior'

@Directive({
  selector: '[href]'
})
export class HrefDirective {

  constructor(
    private el: ElementRef,
    private wezeolyticsBehavior: WezeolyticsBehavior
  ) {}
  
  @HostListener('click') onClick() {
    let path = `(${this.wezeolyticsBehavior.getElementPath(this.el.nativeElement)})`
    
    this.wezeolyticsBehavior.event(
      'href',
      `${this.el.nativeElement.textContent} ${path}`,
      ''
    )
  }

}

import { Directive, ElementRef,  HostListener } from '@angular/core'

import { WezeolyticsBehavior }  from '../../wezeolytics-behavior'

@Directive({
  selector: 'ion-menu'
})

export class IonMenuDirective {

  constructor(
    private el: ElementRef,
    private wezeolyticsBehavior: WezeolyticsBehavior
  ) {}

  @HostListener('ionDidOpen')  menuOpenHandler() {
    this.wezeolyticsBehavior.event(
      'menu open',
      this.wezeolyticsBehavior.getElementPath(this.el.nativeElement),
      ''
    )
  }
  @HostListener('ionDidClose') menuCloseHandler() {
    this.wezeolyticsBehavior.event(
      'menu close',
      this.wezeolyticsBehavior.getElementPath(this.el.nativeElement),
      ''
    )
  }

}

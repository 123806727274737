import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'ct-call-note-modal',
  templateUrl: './call-note-modal.component.html',
  styleUrls: ['./call-note-modal.component.scss'],
})
export class CtCallNote implements OnInit {

  constructor(public modalController: ModalController) { }

  ngOnInit() {}

  closeModal = () => {
    return this.modalController.dismiss()
  }

}

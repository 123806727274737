import { Injectable }       from '@angular/core'

import { BehaviorSubject }  from 'rxjs'
import { map }              from 'rxjs/operators'

import { AuthService }      from './auth.service'
import { CallingService }   from '../call/calling.service'
import { UserApi }          from './user.api'
import { UserUtil }         from './user.util'

export enum UserStatus {
  Online,
  Offline,
  Idle,
}

@Injectable({
  providedIn: 'root'
})
export class UserService {

  _countries$ = new BehaviorSubject<any>([])
  _agents = []
  _rawAgents = []
  _contacts = []
  _rawContacts = []
  _user$ = new BehaviorSubject<any>({})
  _userStatus$ = new BehaviorSubject<UserStatus>(UserStatus.Offline)

  constructor(
    private authService:    AuthService,
    private callingService: CallingService,
    private userApi:        UserApi,

    public userUtil:        UserUtil,
  ) {
    this.authService.login$().subscribe(
      user => {
        console.log(user)
        this._user$.next(user)
        this._userStatus$.next(UserStatus.Online)

        this.countries().subscribe(
          countries => {
            this._countries$.next(countries)
            this.callingService.changeCountry(countries.find(
              country => country.country_code == 'SK')
            )
          }
        )

        this.callingService.callers().subscribe(callers => {
          if (callers && callers.length) {
            let currentCaller = callers.find(caller => caller.id == user.outbound_id)

            if (typeof currentCaller != 'undefined') {
              this.callingService.changeCaller(currentCaller).subscribe()
            }
          }
        })

        this.userContacts().subscribe(contacts => {})
        this.agents().subscribe(agents => {})
      }
    )

    this.authService.logout$().subscribe(() => {
      this.clearCache()
      this.callingService.clearCache()
    })
  }

  reset(email) {
    return this.userApi.reset(email).pipe(
      map(
        reset => {
          if (!reset['success']) {
            return
          }

          return reset
        }
      )
    )
  }

  callHistory() {

    return this.userApi.callHistory().pipe(
      map(
        resp => {
          if (!resp['success']) {
            return []
          }

          return resp['data']
        }
      )
    )
  }

  getUserThirdPartyIntegrations() {

  }

  agents() {

    return this.userApi.agents().pipe(
      map(
        resp => {
          if (!resp['success']) {
            return []
          }

          this._rawAgents = resp['data']
          this._agents = this.userUtil.mapAgents(resp['data'])

          return resp['data']
        }
      )
    )
  }

  countries() {

    return this.userApi.countries().pipe(
      map(
        resp => {
          if (!resp['success']) {
            return []
          }

          return this.userUtil.sortCountries(resp['data'])
        }
      )
    )
  }

  agentsList() {

    return this.userApi.agentsList().pipe(
      map(
        resp => {
          if (!resp['success']) {
            return []
          }

          return resp['data']
        }
      )
    )
  }

  transferListOfAgents() {

    return this.userApi.transferListOfAgents().pipe(
      map(
        resp => {
          if (!resp['success']) {
            return []
          }

          return resp['data']
        }
      )
    )
  }

  userContacts() {

    return this.userApi.userContacts().pipe(
      map(
        resp => {
          if (!resp['success']) {
            return []
          }

          this._rawContacts = resp['data']
          this._contacts = this.userUtil.mapContacts(resp['data'])

          return resp['data']
        }
      )
    )
  }

  changeUserStatus(status) {
    this._userStatus$.next(status)
  }

  getUserStatus$() {
    return this._userStatus$
  }

  getUser$() {
    return this._user$
  }

  getCountries$() {
    return this._countries$
  }

  getContacts() {
    return this._contacts
  }

  getRawContacts() {
    return this._rawContacts
  }

  getAgents() {
    return this._agents
  }

  getRawAgents() {
    return this._rawAgents
  }

  clearCache() {
    this._countries$.next([])
    this._agents = []
    this._rawAgents = []
    this._contacts = []
    this._rawContacts = []
    this._user$.next({})
    this._userStatus$.next(UserStatus.Offline)
  }
}

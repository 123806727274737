import { Pipe, PipeTransform } from '@angular/core'

import { PhoneNumberUtilService } from '../phonenumber.util'

/**
 * Sformatuje cislo do medz. formatu
 *
 * value: cislo bez predvolby
 * countryCode: napr. 421 pre SK
 */
@Pipe({name: 'phoneNumber'})
export class PhoneNumberFormatterPipe implements PipeTransform {
  
  constructor(
    private phoneUtil: PhoneNumberUtilService
  ) { }

    /**
     * Sformatuje cislo do daneho formatu
     *
     * @param value
     * @param countryCode
     * @param format
     * @returns {string}
     */
    transform(value: string, countryCode: string, format?: number): any {
        try {
            return this.phoneUtil.format(value, countryCode, format)
        } catch (e) {
            return value
        }
    }
}

import { Injectable, NgZone }   from '@angular/core'
import { Router }               from '@angular/router'

// import { Firebase }             from '@ionic-native/firebase/ngx'
import { Platform  }            from '@ionic/angular'
import { Push, PushObject }     from '@ionic-native/push/ngx'
import { of }                   from 'rxjs'
import { map }                  from 'rxjs/operators'

import { ENV }                  from 'src/environments/environment'

@Injectable({
  providedIn: 'root'
})
export class NotificationsNativeApi {

  _token
  // _firebase: Firebase
  // _firebase
  _pushObject: PushObject

  constructor(
    private platform:         Platform,
    private push:             Push,
    private zone:             NgZone,

    public router:            Router,
  ) {
    this.zone.runOutsideAngular(() => {
      // this._firebase = new Firebase()
    })
  }

  register$(callback) {
    // if (this.platform.is('ios')) {
    //   // await this._firebase.requestPermission()
    //   await this._firebase.grantPermission()
    // }
    //
    // return this._firebase.getToken()
    this._pushObject = this.push.init(ENV.pushOptions)

    return this._pushObject.on('registration').pipe(
      map(async registration => {
        await callback(registration.registrationId)
        return registration.registrationId
      })
    )
  }

  // handleTokenRefresh$() {
  //   // return this._firebase.onTokenRefresh()
  //   return this._pushObject.on('registration')
  // }

  handleError$() {
    if (!this._pushObject) {
      return of({})
    }

    return this._pushObject.on('error')
  }

  onNotification$() {
    if (!this._pushObject) {
      return of(null)
    }
    // return this._firebase.onMessage()
    // .pipe(
    //   merge(this._firebase.onBackgroundMessage())
    // )
    // return this._firebase.onNotificationOpen()
    return this._pushObject.on('notification')
  }

  subscribeToTopic(topic) {
    // return this._firebase.subscribe(topic)
    this._pushObject.subscribe(topic)
  }

  unsubscribeFromTopic(topic) {
    // return this._firebase.unsubscribe(topic)
    this._pushObject.unsubscribe(topic)
  }

  unregister() {
    if (!this._pushObject) {
      return
    }
    // return this._firebase.revokeToken()
    // return this._firebase.unregister()
    return this._pushObject.unregister()
  }

  clearAllNotifications() {
    if (!this._pushObject) {
      return Promise.resolve()
    }

    return this._pushObject.clearAllNotifications()
  }
}

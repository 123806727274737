export const ENV_BASE = {
	http: {
    headers: {
      'Content-Type': 'application/json',
    },

    API_PROTOCOL: window.localStorage['vct_apiProtocol'] || 'https://',
    API_HOST: window.localStorage['vct_apiHost']
      || 'api.cloudtalk.io/app/',
    API_VERSION: window.localStorage['vct_apiVersion'] || ''
  },

	appPrefix: 'ct',
	firebaseConfig: {
		apiKey: 'AIzaSyBkGQTTsPZxkX16EA6cGnR4lyQBjtxIXAg',
    authDomain: 'cloudtalk-3e397.firebaseapp.com',
    databaseURL: 'https://cloudtalk-3e397.firebaseio.com',
    projectId: 'cloudtalk-3e397',
    storageBucket: 'cloudtalk-3e397.appspot.com',
    messagingSenderId: '654253980406',
    appId: '1:654253980406:web:8d25df5b2e0354ea'
  },
	pushOptions: {
		android: {},
		ios: {
			alert: true,
			badge: true,
			sound: true,
			clearBadge:	true
		},
		windows: {},
		browser: {
			pushServiceURL: ''
		}
	},
	mocks: false,
  wezeolytics: {
    googleAnalyticsID: 'UA-141117181-1'
  },
	sip: {
		dnsServer: 'sip.cloudtalk.io'
	},
  sentry: {
    dsn: false
  }
}

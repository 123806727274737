import {
  Component,
  OnInit,
  OnDestroy
}                                 from '@angular/core'

import { ModalController }        from '@ionic/angular'
import { Subscription }           from 'rxjs'

import { CallingService }         from '../../call/calling.service'

@Component({
  selector: 'ct-calling-number-modal',
  templateUrl: './calling-number-modal.component.html',
  styleUrls: ['./calling-number-modal.component.scss'],
})
export class CldCallingNumberModal implements OnInit, OnDestroy {

  callers
  recentCallers = []
  filteredRecentCallers = []
  filteredCallers
  searchQuery

  _getRecentCallersSubscription$: Subscription

  constructor(
    private callingService: CallingService,

    public modalController: ModalController,
  ) {}

  ngOnInit() {
    this._getRecentCallersSubscription$ = this.callingService.getRecentCallers$().subscribe(
      recents => {
        this.filteredRecentCallers = recents
        this.recentCallers = recents
        this.filteredCallers = this.callers.filter(
          caller => typeof recents.find(
            recentCaller => caller.id == recentCaller.id
          ) == 'undefined'
        )
      }
    )
  }

  ngOnDestroy() {
    this._getRecentCallersSubscription$.unsubscribe()
  }

  async presentModal(data) {

    const modal = await this.modalController.create({
      cssClass: 'ct-modal',
      component: CldCallingNumberModal,
      componentProps: { ...data }
    })

    return await modal.present()
  }

  closeModal = () => {
    this.modalController.dismiss()
  }

  search() {
    this.filteredCallers = this.callers.filter(
      caller => typeof this.recentCallers.find(
        recentCaller => caller.id == recentCaller.id
      ) == 'undefined' &&
      this._callers_searchFilter(caller)
    )

    this.filteredRecentCallers = this.recentCallers.filter(
      recentCaller => this._callers_searchFilter(recentCaller)
    )
  }

  _callers_searchFilter(caller) {
    return (
      this._removeAccents(
        caller.internal_name.toLowerCase()
      ).indexOf(
        this._removeAccents(this.searchQuery.toLowerCase())
      ) != -1 ||

      this._removeAccents(
        this.callingService.callerNumber(caller).replace(/\s|\/|\+/g, '')
      ).indexOf(
        this._removeAccents(this.searchQuery.toLowerCase().replace(/\s|\/|\+/g, ''))
      ) != -1
    )
  }

  selectCaller(caller) {
    this.callingService.changeCaller(caller).subscribe()
    this.closeModal()
  }

  _removeAccents(string) {
    if (typeof String.prototype.normalize === 'function') {
      // prevedie string na Unicode normalizaciu a vyhodi specialne znaky - interpunkciu a pod.
      return string.normalize('NFD').replace(/[\u0300-\u036f]/g, '')
    }
    return string
  }

}

import { Injectable } from '@angular/core'
import { Event,  NavigationEnd, NavigationStart, Router }
from '@angular/router'

import { GoogleAnalyticsIntegration } from '../integrations/google-analytics.integration'

@Injectable({
  providedIn: 'root'
})
export class RoutingListener {

  category = 'Routing'
  pageStartTime
  activityInterval
  userActivityBlocks = [] // Block of user activity
  logsInRoute = 0
  blockInterval = 15000

  constructor (
    private googleAnalytics:      GoogleAnalyticsIntegration,
    private router:               Router,
  ) {}

  start(wezeolyticsBehavior) {
    this.pageStartTime = Date.now()
    this.router.events.subscribe((event: Event) => {

      if (event instanceof NavigationStart) {
        let time = Date.now() - this.pageStartTime
        let totalTime  = new Date(time)

        if (time <= 500 ) {
          return 0
        }

        let totalTimeStr = `${totalTime.getHours() - 1}h ${totalTime.getMinutes()}m ${totalTime.getSeconds()}s`
        let text = `totalTime in page: ${totalTimeStr} active percent: ${this._getActivePercentage()}%`
        wezeolyticsBehavior.event('leaving page', '', text)
      }

      if (event instanceof NavigationEnd) {
        wezeolyticsBehavior.event('route', '', '')
        this.googleAnalytics.page(this.router)
        this.logsInRoute = 1
        this.userActivityBlocks = [true]
        this.activityInterval = setInterval(this._activityMonitor.bind(this), this.blockInterval)
      }
    })
  }

  _getActivePercentage() {
    let percentage = 0
    for (let activityBlock of this.userActivityBlocks) {      
      if (activityBlock) {
        percentage += 100
      }
    }
    percentage = percentage / this.userActivityBlocks.length
    return Math.round(percentage)
  }

  _activityMonitor() {
    let active = (this.logsInRoute >= 1)
    this.userActivityBlocks.push(active)
    this.logsInRoute = 0
  }

}

import {
   Directive, ElementRef, HostListener
 }                              from '@angular/core'

import { WezeolyticsBehavior }  from '../../wezeolytics-behavior'

@Directive({
  selector: 'ion-button'
})
export class IonButtonDirective {

  constructor(
    private el: ElementRef,
    private wezeolyticsBehavior: WezeolyticsBehavior
  ) {}

  @HostListener('click') onClick() {
    let path = `(${this.wezeolyticsBehavior.getElementPath(this.el.nativeElement)})`
    this.wezeolyticsBehavior.event(
      'button',
      `${this.el.nativeElement.textContent} ${path}`,
      ''
    )
  }
}

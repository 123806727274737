import { Injectable } from '@angular/core'

@Injectable()
export class UserUtil {

	constructor() {}

  mapAgents(agents) {
    let mappedAgents = []

    for (let agent of agents) {
      let agentFirstLetter = agent.lastname && agent.lastname.length ? agent.lastname[0] : agent.firstname[0]

      let agentLetter = mappedAgents.find(letter => letter && letter.letter == agentFirstLetter)

      if (typeof agentLetter == 'undefined') {
        mappedAgents.push({letter: agentFirstLetter, agents: [agent]})
        continue
      }

      agentLetter.agents.push(agent)
    }

    return mappedAgents.sort(this._sortContactsByAlpabet)
  }

  mapContacts(contacts) {

    let mappedContacts = []

    for (let contact of contacts) {
			let name = contact.name.replace(/\s\s+/g, ' ')
      let contactNames = name.split(' ')
      let contactFirstLetter = contactNames.length > 1 ? contactNames[1][0] : contactNames[0][0]

      let contactLetter = mappedContacts.find(letter => letter && letter.letter == contactFirstLetter)

      if (typeof contactLetter == 'undefined') {
        mappedContacts.push({letter: contactFirstLetter, contacts: [contact]})
        continue
      }

      contactLetter.contacts.push(contact)
    }

    return mappedContacts.sort(this._sortContactsByAlpabet)
  }

	sortCountries(countries) {
		return countries.sort(this._sortCountriesByAlphabet)
	}

	findContactByNumber(contacts, number) {
    let contactMatch = contacts.find(contact => contact.public_number == number)

    if (typeof contactMatch == 'undefined') {
      return null
    }

    return contactMatch
  }

	_sortContactsByAlpabet(a, b) {
    return a.letter.localeCompare(b.letter)
  }

	_sortCountriesByAlphabet(a, b) {
		return a.name.localeCompare(b.name)
	}

}

import { Injectable }               from '@angular/core'

import { PhoneNumberUtil }          from 'google-libphonenumber'
import { PhoneNumberFormat as PNF } from 'google-libphonenumber'

export enum PhoneNumberFormat {
    E164,
    INTERNATIONAL,
    NATIONAL,
    RFC3966
}

@Injectable()
export class PhoneNumberUtilService {

  /**
   * Sformatuje cislo do daneho formatu
   *
   * @param value
   * @param countryCode
   * @param format
   * @returns {string}
   */
  format(value: string, countryCode: string, format?: number): any {    
    let phoneUtil = PhoneNumberUtil.getInstance()

    /// E164:0, INTERNATIONAL:1, NATIONAL:2, RFC3966:3
    let phoneFormat
    switch (format) {
      case PhoneNumberFormat.E164:
        phoneFormat = PNF.E164
        break
      case PhoneNumberFormat.INTERNATIONAL:
        phoneFormat = PNF.INTERNATIONAL
        break
      case PhoneNumberFormat.NATIONAL:
        phoneFormat = PNF.NATIONAL
        break
      case PhoneNumberFormat.RFC3966:
        phoneFormat = PNF.RFC3966
        break
      default:
        phoneFormat = PNF.INTERNATIONAL
        break
    }

    let region

    // ak je country code ZZ tak cislo musi byt v Internation formate
    if (countryCode == 'ZZ') {
      region = 'ZZ'
    } else {
      region = phoneUtil.getRegionCodeForCountryCode(parseInt(countryCode, 10))
    }
    
    try {
      let phoneNumber = phoneUtil.parse(value.toString(), region)
      
      return phoneUtil.isValidNumber(phoneNumber) ? phoneUtil.format(phoneNumber, phoneFormat) : value
    } catch (e) {
      console.log(e)
      return value
    }
  }

  isValidNumber(value, countryCode = 'ZZ') {
    let phoneUtil = PhoneNumberUtil.getInstance()

    let region
    // ak je country code ZZ tak cislo musi byt v Internation formate
    if (countryCode === 'ZZ') {
      region = 'ZZ'
    } else {
      region = phoneUtil.getRegionCodeForCountryCode(parseInt(countryCode, 10))
    }

    try {
      const phoneNumber = phoneUtil.parse(value.toString(), region)
      return phoneUtil.isValidNumber(phoneNumber)
    } catch (e) {
      return false
    }
  }

  formatOutOfCountryCallingNumber(value, countryCode = 'ZZ') {
    let phoneUtil = PhoneNumberUtil.getInstance()

    let region
    // ak je country code ZZ tak cislo musi byt v Internation formate
    if (countryCode === 'ZZ') {
      region = 'ZZ'
    } else {
      region = phoneUtil.getRegionCodeForCountryCode(parseInt(countryCode, 10))
    }

    try {
      const phoneNumber = phoneUtil.parse(value.toString(), region)
      return phoneUtil.isValidNumber(phoneNumber) ? phoneUtil.formatOutOfCountryCallingNumber(phoneNumber, region) : value
    } catch (e) {
      return value
    }
  }
  
  regionCodeFromNumber(number) {
    let phoneUtil = PhoneNumberUtil.getInstance()
    
    try {
      const phoneNumber = phoneUtil.parse(number.toString(), 'ZZ')
      
      return phoneUtil.getRegionCodeForNumber(phoneNumber)
    } catch (e) {
      console.log(e)
      return 'UK'
    }
  }

  /**
   * Vrati pre country code skratku regionu napr. 421 -> SK
   *
   * @param number - cislo v medzinarodnom formate
   * @returns {string}
   */
  countryCodeFromNumber(number: string): number {
    let phoneUtil = PhoneNumberUtil.getInstance()
    
    try {
      const phoneNumber = phoneUtil.parse(number.toString(), 'ZZ')

      return phoneUtil.isValidNumber(phoneNumber) ? phoneNumber.getCountryCode() : null
    } catch (e) {
      return null
    }
  }
}

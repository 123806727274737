import { Injectable } from '@angular/core'
import { Platform } from '@ionic/angular'

@Injectable({
  providedIn: 'root'
})
export class ApplicationStateService {

  constructor(
    private platform: Platform,
  ) {}

  start(wezeolyticsBehavior) {
    this.platform.ready().then(() => {

      this.platform.pause.subscribe(
        () => wezeolyticsBehavior.event('background', 'pause', '')
      )

      this.platform.resume.subscribe(
        () => wezeolyticsBehavior.event('foreground', 'resume', '')
      )

      /*
      this.platform.resize.subscribe(()=>{
        // Toto je pre učel testu nepužívať trigeruje sa mnoho krát po sebe
        analytics.addToLog('app window resize', 'Resize')
      });
      */
    })
  }

}

import { Injectable } from '@angular/core'

import { WzoHttpAuth } from '../wzoHttp/wzoHttpAuth.service'

@Injectable({
  providedIn: 'root'
})
export class NotificationsBackendApi {
  constructor(
    private wzoHttpAuth: WzoHttpAuth
  ) {}

  registerDevice(token, platform = 'android') {
    return this.wzoHttpAuth.get(`user-endpoints/register-device/${token}/${platform}.json`)
  }

  unregisterDevice(token) {
    return this.wzoHttpAuth.get(`user-endpoints/unregister-device/${token}.json`)
  }
}

import { Injectable } from '@angular/core'
import { VoiceSettingService } from './voice-setting.service'

@Injectable({
  providedIn: 'root'
})
export class VoiceProviderService {
  private _audio: HTMLAudioElement
  private _audioSound: HTMLAudioElement
  private _playPromise: Promise<any>
  public isPlaying = false

  constructor() {
    this._audio = new Audio()
    this._audioSound = new Audio()
    this._audio.autoplay = false
    this._audioSound.autoplay = false
  }

  get audio(): HTMLAudioElement {
    return this._audio
  }

  set audio(value: HTMLAudioElement) {
    this._audio = value
  }

  get audioSound(): HTMLAudioElement {
    return this._audioSound
  }

  set audioSound(value: HTMLAudioElement) {
    this._audioSound = value
  }

  pauseSound() {
    if (this._playPromise !== undefined && this.isPlaying) {
      this.isPlaying = false
      this._playPromise.then((_) => {
        this._audioSound.pause()
      }).catch(error => {})
    }
  }

  playSound() {
    this.isPlaying = true
    this._audioSound.load()
    this._audioSound.autoplay = true
    this._audioSound.currentTime = 0
    this._playPromise = this._audioSound.play()
  }
}

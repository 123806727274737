import { 
  Component, 
  OnInit, 
  Input, 
  ChangeDetectorRef, 
  OnDestroy, 
  ChangeDetectionStrategy 
}                                   from '@angular/core'

import { ModalController }          from '@ionic/angular'

import { CldCallingNumberModal }    from '../calling-number-modal/calling-number-modal.component'
import { CallingService }           from '../../call/calling.service'
import { UserService }              from '../../user/user.service'

@Component({
  selector: 'ct-caller',
  templateUrl: './ct-caller.component.html',
  styleUrls: ['./ct-caller.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CtCaller implements OnInit, OnDestroy {
  
  @Input() select
  
  _callers
  _callerSubscription$
  _userSubscription$
  _selectCallerModal
  
  caller

  constructor(
    private callingService:   CallingService,
    private changeDetector:   ChangeDetectorRef,
    private modalController:  ModalController,
    private userService:      UserService,
  ) { }

  ngOnInit() {
    
    this._callerSubscription$ = this.callingService.getCaller$().subscribe(caller => {
      this.caller = caller
      this.changeDetector.detectChanges()
    })
    
    this._userSubscription$ = this.userService.getUser$().subscribe(user => {
      if (!user || !user.id) {
        return
      }
    
      this.callingService.getCallers$().subscribe(callers => {
        this._callers = callers
      })
    })
  }
  
  ngOnDestroy() {
    
    if (this._callerSubscription$) {
      this._callerSubscription$.unsubscribe()
    }
    
    if (this._userSubscription$) {
      this._userSubscription$.unsubscribe()
    }
  }
  
  async selectCaller() {
    
    if (!this.select) {
      return
    }
    
    this._selectCallerModal = await this.modalController.create({
      cssClass: 'ct-modal',
      component: CldCallingNumberModal,
      componentProps: { callers: this._callers }
    })
    
    return await this._selectCallerModal.present()
  }

}

import { Injectable } 	from '@angular/core'

import { of } 			from 'rxjs'

import { WzoHttpAuth } 	from '../../wezeo/wzoHttp/wzoHttpAuth.service'
import { ENV } 			from 'src/environments/environment'

@Injectable({
	providedIn: 'root'
})
export class CallPlaybackService {
	
	constructor(
		private wzoHttpAuth: WzoHttpAuth
	) {
		
	}

	getPlaybackUrl(callId) {
		return this.wzoHttpAuth.getPlainUrl(`call-history/recording/${callId}.json`)
  }
    
  trackPlaying(callId) {
    if (ENV.mocks) {
			return of({})
    }
      
    return this.wzoHttpAuth.get(`call-history/recording-played/${callId}.json`)
  }
	
}

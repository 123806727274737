import {
  Directive, ElementRef, HostListener
}                               from '@angular/core'

import { WezeolyticsBehavior }  from '../../wezeolytics-behavior'

@Directive({
  selector: 'ion-back-button'
})
export class IonbackbuttonDirective {

  constructor(
    private el:                   ElementRef,
    private wezeolyticsBehavior:  WezeolyticsBehavior
  ) {}

  @HostListener('click') onClick() {
    let category = 'back-button'

    this.wezeolyticsBehavior.event(
      category,
      this.wezeolyticsBehavior.getElementPath(this.el.nativeElement),
      ''
    )
  }
}

import {
	Injectable,
	Output,
	EventEmitter
} 															from '@angular/core'

import * as SIP 								from 'sip.js'

import { VoiceSettingService } 	from './voice-setting.service'
import { VoiceProviderService } from './voice-provider.service'
import { ENV } 									from 'src/environments/environment'

@Injectable({
	providedIn: 'root'
})
export class SipService {

	_call
	_registered = false
	_userAgent = null

	@Output() _registeredEvent = new EventEmitter()
	@Output() _unregisteredEvent = new EventEmitter()
	@Output() _incomingCallEvent = new EventEmitter()
	@Output() _disconnectEvent = new EventEmitter()

	constructor(
		private voiceSetting: VoiceSettingService,
		private voiceProvider: VoiceProviderService
	) {}

	connect(name, password) {
		if (this._userAgent != null) {
      this._userAgent.register()
      return true
    }

		if (SIP.WebRTC.isSupported()) {
      return this.createUserAgent(name, password)
		}

		return false
	}

	disconnect() {
		console.log('sip disconnect', this._userAgent)
		if (!this._userAgent) {
			return
    }

		if (this._userAgent.isRegistered()) {
			this._userAgent.unregister()
		}

		this._userAgent = null
		this._disconnectEvent.emit()
	}

	createUserAgent(name, password) {
		// if (name == '100552031016') {
		// 	name = '100552031013'
		// 	password = 'TJX2ALp7C@1'
		// }

    this._userAgent = new SIP.UA({
      uri: `sip:${name}@${ENV.sip.dnsServer}`,
			password,
      wsServers: [`wss://${ENV.sip.dnsServer}:443`],
      hackIpInContact: true,
      hackWssInTransport: true,
      rtcpMuxPolicy: 'negotiate',
      registerExpires: 60,
      traceSip: false,
      iceCheckingTimeout: 3000,
      userAgentString: 'CloudTalk',
      log: {
        level: 'debug'
      }
    })
		// original value registerExpires: 300

    this._userAgent.on('registered', 					() 			=> this._register())
    this._userAgent.on('registrationFailed', 	(response, cause) => this._failRegistration(response, cause))
    this._userAgent.on('unregistered', 				() 			=> this.unregister())
    this._userAgent.on('disconnected', 				() 			=> this.disconnect())
	}

	getCall() {
		return this._call
	}

	accept() {
		if (!this._call) {
			return
		}

		return this._call.accept(this.mediaOptions())
	}

	bye() {
		this._call.bye()
	}

	cancel() {
		this._call.cancel()
	}

	reject() {
		this._call.reject()
	}

	invite(uri) {
		if (!this._userAgent) {
			return
		}

		this._call = this._userAgent.invite(uri, this.mediaOptions())
		return this._call
	}

	mute() {
		this._call.mute()
	}

	unmute() {
		this._call.unmute()
	}

	hold() {
		this.dtmf('#21#', {
      'duration': 70,
      'interToneGap': 50,
    })
		this.mute()
		this.voiceProvider.audio.volume = 0
	}

	unhold() {
		this.dtmf('#20#', {
      'duration': 70,
      'interToneGap': 50,
    })
		this.unmute()
		this.voiceProvider.audio.volume = 1
	}

	dtmf(tone, options = {}) {
		this._call.dtmf(tone, options)
	}

	transfer(number) {
		this._call.refer(number)
	}

	externalTransfer(number) {
		this.dtmf(`##${number}#`, {
			'duration': 70,
      'interToneGap': 50,
    })
	}

	_register() {
    if (!this._registered) {
      this._listenToIncomingCalls()
      this._registered = true
			this._registeredEvent.emit()
    }
	}

	_failRegistration(response, cause) {
		console.log(response, cause)
		// alert('failed registration response ' + response)
		// alert('failed registration cause ' + cause)
		this.unregister()
	}

	unregister() {
		if (this._userAgent && this._userAgent.isRegistered()) {
      this._userAgent.unregister()
			this._userAgent = null
			this._registered = false
			this._unregisteredEvent.emit()
    }
	}

	_listenToIncomingCalls() {
		this._userAgent.on('invite', session => {
			console.log('userAgent invite')
			this._call = session
			this._incomingCallEvent.emit(session)
		})
	}

	getIncomingCalls$() {
		return this._incomingCallEvent
	}

	mediaOptions() {
    return {
			media: {
				constraints: {
          audio: {
						deviceId: this.voiceSetting.audioInput ? {
							exact: this.voiceSetting.audioInput
						} : undefined
					},
          video: false
        },
        render: {
          remote: this.voiceProvider.audio
        }
			},
      RTCConstraints: {
        optional: [{
          DtlsSrtpKeyAgreement: true
        }],
        mandatory: {
          OfferToReceiveVideo: false
        }
      }
    }
	}

}

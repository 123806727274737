import { Injectable } 				from '@angular/core'

import { PermissionsService } from '../permissions/permissions.service'
import { WzoOfflineService } 	from '../../wezeo/offline/wzo-offline.service'
import { WzoErrorService } 		from '../../wezeo/wzoError/wzoError.service'

@Injectable({
	providedIn: 'root'
})
export class CallUtil {

	constructor(
		private permissionsService: PermissionsService,
    private offlineService:     WzoOfflineService,
		private errorService:				WzoErrorService,
	) {}

	formatCallTime(time, minutesTrailingZero = false) {
		let min = Math.floor(time / 60)
		let sec = time % 60

		let timestring = minutesTrailingZero ?
										`${min < 10 ? '0' + min : min}:${sec < 10 ? '0' + sec : sec}` :
										`${min}:${sec < 10 ? '0' + sec : sec}`
		return timestring
	}

	async arePermissionsGranted() {
		const permissionsEnabled = await this.permissionsService.getPermissions()
		if (!permissionsEnabled) {
			this.errorService.addMessage({
				text: 'Permissions not granted',
				type: 'warning'
			})

			return false
		}

		return true
	}

	isOnline() {
		if (!this.offlineService.getOnlineStatus$().getValue()) {
			this.errorService.addMessage({
				text: 'No internet connection!',
				type: 'error'
			})

			return false
		}

		return true
	}

	translateSipErrors(errorCode) {
    let messages = {
      400: 'You are trying to perform bad request',
      403: 'You are not allowed to perform this request',
      404: 'Phone number is not available or does not exist',
      480: 'Temporarily Unavailable',
      486: 'User is busy now. Try again later.',
      487: 'Request has been terminated',
      500: 'Internal server error. Try again later.',
      503: 'Service is unavailable at the moment. Try again later.',
    }

    if (errorCode && messages[errorCode]) {
      return messages[errorCode]
    } else {
      return 'Error occured. Please contact our support.'
    }
	}
}

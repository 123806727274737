import { Component, OnInit, Input }         from '@angular/core'

import { interval, timer, BehaviorSubject } from 'rxjs'
import { takeWhile, finalize }              from 'rxjs/operators'

import { CallService }                      from '../../call/call.service'

@Component({
  selector: 'ct-keypad',
  templateUrl: './ct-keypad.component.html',
  styleUrls: ['./ct-keypad.component.scss'],
})
export class CtKeypad implements OnInit {

  @Input() isDialpad = false

  _animationsQueue = []
  _finishZeroDial$ = new BehaviorSubject<boolean>(false)

  constructor(
    private callService: CallService
  ) {}

  ngOnInit() {}

  dial(value) {
    this.callService.keypadDial({
      value,
      isDialpad: this.isDialpad
    })
    this._animateDialpadClick(value)
  }

  _animateDialpadClick(input) {

    let activeEl = document.getElementsByClassName(`keypad__button--${input}`)[0]
    if (!activeEl) {
      return
    }

    // "cancel" ongoing animation
    activeEl.classList.remove('mystyle')
    activeEl.classList.remove('opacity')
    let ongoingIndex = this._animationsQueue.findIndex(
      animation => animation && animation.input == input
    )
    if (ongoingIndex != -1) {
      clearTimeout(this._animationsQueue[ongoingIndex].timeout)
      clearTimeout(this._animationsQueue[ongoingIndex].opacityTimeout)
      delete this._animationsQueue[ongoingIndex]
    }

    activeEl.classList.add('mystyle')

    let animationOpacity = setTimeout(function() {
      activeEl.classList.add('opacity')
    }, 300)

    let animationCleanup = setTimeout(function() {
      activeEl.classList.remove('mystyle')
      activeEl.classList.remove('opacity')
    }, 600)

    this._animationsQueue.push({
      'timeout': animationCleanup,
      'opacityTimeout': animationOpacity,
      'input': input
    })
  }

  startDialZeroCounter() {
    interval(10).pipe(
      takeWhile(val => !this._finishZeroDial$.getValue() && val < 100),
      finalize(() => {
        if (!this._finishZeroDial$.getValue()) {
          this.dial('+')
          return
        }

        this.dial('0')
      })
    ).subscribe()
  }

  finishDialZeroCounter() {
    this._finishZeroDial$.next(true)

    timer(100).pipe(
      finalize(() => {
        this._finishZeroDial$.next(false)
      })
    ).subscribe()
  }

}

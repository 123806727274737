import { ErrorHandler, Injectable } from '@angular/core'
import { Platform } from '@ionic/angular'

import * as Sentry from 'sentry-cordova'
import { ENV } from 'src/environments/environment'

@Injectable({
  providedIn: 'root'
})
export class WzoSentry implements ErrorHandler {

  constructor(private platform: Platform) {
    // super()

    this.platform.ready().then(() => {
      // testing@wezeo.com
      // dsn: 'https://dbd172ad946e487c9ee3f75be01ec708@sentry.io/1475525'
      if (ENV.sentry.dsn) {
        Sentry.init({
          dsn: (ENV.sentry.dsn as any)
        })
      }
    })
  }

  handleError(error) {
    console.error('WzoSentry handleError', error)
    // super.handleError(error)

    try {
      Sentry.captureException(error.originalError || error)
    } catch (e) {
      console.error(e)
    }
  }
}

import { Injectable } 	from '@angular/core'

import { of } 					from 'rxjs'

import { WzoHttpAuth } 	from '../../wezeo/wzoHttp/wzoHttpAuth.service'
import { ENV } 					from 'src/environments/environment'

@Injectable({
	providedIn: 'root'
})
export class CallingApi {
	
	constructor(
		private wzoHttpAuth: WzoHttpAuth
	) {
		
	}
	
	callers() {
		if (ENV.mocks) {
			return of({})
		}
		
		return this.wzoHttpAuth.get('call-numbers.json')
	}
	
	changeCaller(callerId) {
		
		if (ENV.mocks) {
			return of({})
		}
		
		return this.wzoHttpAuth.post(
			'agents/change-caller-id.json', 
			JSON.stringify({ outbound_id: callerId })
		)
	}
	
}
